@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../../fonts/MuseoSansCyrl.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('../../fonts/MuseoSansCyrl_700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
