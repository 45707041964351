.color-accent-10 {
  color: var(--mg-color-accent-10) !important;
}
.color-accent-20 {
  color: var(--mg-color-accent-20) !important;
}
.color-accent-25 {
  color: var(--mg-color-accent-25) !important;
}
.color-accent-28 {
  color: var(--mg-color-accent-28) !important;
}
.color-accent-30 {
  color: var(--mg-color-accent-30) !important;
}
.color-accent-40 {
  color: var(--mg-color-accent-40) !important;
}
.color-accent-50 {
  color: var(--mg-color-accent-50) !important;
}

.color-primary-30 {
  color: var(--mg-color-primary-30) !important;
}
.color-primary-40 {
  color: var(--mg-color-primary-40) !important;
}
.color-primary-50 {
  color: var(--mg-color-primary-50) !important;
}

.color-grey-10 {
  color: var(--mg-color-grey-10) !important;
}
.color-grey-20 {
  color: var(--mg-color-grey-20) !important;
}
.color-grey-30 {
  color: var(--mg-color-grey-30) !important;
}
.color-grey-40 {
  color: var(--mg-color-grey-40) !important;
}
.color-grey-50 {
  color: var(--mg-color-grey-50) !important;
}

.color-green-10 {
  color: var(--mg-color-green-10) !important;
}
.color-green-20 {
  color: var(--mg-color-green-20) !important;
}
.color-green-30 {
  color: var(--mg-color-green-30) !important;
}
.color-green-40 {
  color: var(--mg-color-green-40) !important;
}
.color-green-45 {
  color: var(--mg-color-green-45) !important;
}
.color-green-50 {
  color: var(--mg-color-green-50) !important;
}
.color-green-60 {
  color: var(--mg-color-green-60) !important;
}

.color-red-10 {
  color: var(--mg-color-red-10) !important;
}
.color-red-20 {
  color: var(--mg-color-red-20) !important;
}
.color-red-30 {
  color: var(--mg-color-red-30) !important;
}
.color-red-40 {
  color: var(--mg-color-red-40) !important;
}
.color-red-50 {
  color: var(--mg-color-red-50) !important;
}
.color-red-60 {
  color: var(--mg-color-red-60) !important;
}

.color-yellow-10 {
  color: var(--mg-color-yellow-10) !important;
}
.color-yellow-20 {
  color: var(--mg-color-yellow-20) !important;
}
.color-yellow-30 {
  color: var(--mg-color-yellow-30) !important;
}
.color-yellow-40 {
  color: var(--mg-color-yellow-40) !important;
}
.color-yellow-50 {
  color: var(--mg-color-yellow-50) !important;
}
.color-yellow-60 {
  color: var(--mg-color-yellow-60) !important;
}

.color-orange-10 {
  color: var(--mg-color-orange-10) !important;
}
.color-orange-20 {
  color: var(--mg-color-orange-20) !important;
}
.color-orange-30 {
  color: var(--mg-color-orange-30) !important;
}
.color-orange-40 {
  color: var(--mg-color-orange-40) !important;
}
.color-orange-50 {
  color: var(--mg-color-orange-50) !important;
}
.color-orange-60 {
  color: var(--mg-color-orange-60) !important;
}

.color-blue-10 {
  color: var(--mg-color-blue-10) !important;
}
.color-blue-20 {
  color: var(--mg-color-blue-20) !important;
}
.color-blue-30 {
  color: var(--mg-color-blue-30) !important;
}
.color-blue-40 {
  color: var(--mg-color-blue-40) !important;
}
.color-blue-50 {
  color: var(--mg-color-blue-50) !important;
}
.color-blue-60 {
  color: var(--mg-color-blue-60) !important;
}

.color-cyan-10 {
  color: var(--mg-color-cyan-10) !important;
}
.color-cyan-20 {
  color: var(--mg-color-cyan-20) !important;
}
.color-cyan-30 {
  color: var(--mg-color-cyan-30) !important;
}
.color-cyan-40 {
  color: var(--mg-color-cyan-40) !important;
}
.color-cyan-50 {
  color: var(--mg-color-cyan-50) !important;
}

.color-salad-30 {
  color: var(--mg-color-salad-30) !important;
}
.color-salad-40 {
  color: var(--mg-color-salad-40) !important;
}

.color-pink-30 {
  color: var(--mg-color-pink-30) !important;
}
.color-pink-40 {
  color: var(--mg-color-pink-40) !important;
}

.color-eggplant-30 {
  color: var(--mg-color-eggplant-30) !important;
}
.color-eggplant-40 {
  color: var(--mg-color-eggplant-40) !important;
}

.color-light {
  color: var(--mg-color-light) !important;;
}
