.bg-accent-10 {
  background-color: var(--mg-color-accent-10) !important;
}
.bg-accent-20 {
  background-color: var(--mg-color-accent-20) !important;
}
.bg-accent-25 {
  background-color: var(--mg-color-accent-25) !important;
}
.bg-accent-28 {
  background-color: var(--mg-color-accent-28) !important;
}
.bg-accent-30 {
  background-color: var(--mg-color-accent-30) !important;
}
.bg-accent-40 {
  background-color: var(--mg-color-accent-40) !important;
}
.bg-accent-50 {
  background-color: var(--mg-color-accent-50) !important;
}

.bg-primary-30 {
  background-color: var(--mg-color-primary-30) !important;
}
.bg-primary-40 {
  background-color: var(--mg-color-primary-40) !important;
}
.bg-primary-50 {
  background-color: var(--mg-color-primary-50) !important;
}

.bg-grey-10 {
  background-color: var(--mg-color-grey-10) !important;
}
.bg-grey-20 {
  background-color: var(--mg-color-grey-20) !important;
}
.bg-grey-30 {
  background-color: var(--mg-color-grey-30) !important;
}
.bg-grey-40 {
  background-color: var(--mg-color-grey-40) !important;
}
.bg-grey-50 {
  background-color: var(--mg-color-grey-50) !important;
}

.bg-green-10 {
  background-color: var(--mg-color-green-10) !important;
}
.bg-green-20 {
  background-color: var(--mg-color-green-20) !important;
}
.bg-green-30 {
  background-color: var(--mg-color-green-30) !important;
}
.bg-green-40 {
  background-color: var(--mg-color-green-40) !important;
}
.bg-green-45 {
  background-color: var(--mg-color-green-45) !important;
}
.bg-green-50 {
  background-color: var(--mg-color-green-50) !important;
}
.bg-green-60 {
  background-color: var(--mg-color-green-60) !important;
}

.bg-red-10 {
  background-color: var(--mg-color-red-10) !important;
}
.bg-red-20 {
  background-color: var(--mg-color-red-20) !important;
}
.bg-red-30 {
  background-color: var(--mg-color-red-30) !important;
}
.bg-red-40 {
  background-color: var(--mg-color-red-40) !important;
}
.bg-red-50 {
  background-color: var(--mg-color-red-50) !important;
}
.bg-red-60 {
  background-color: var(--mg-color-red-60) !important;
}

.bg-yellow-10 {
  background-color: var(--mg-color-yellow-10) !important;
}
.bg-yellow-20 {
  background-color: var(--mg-color-yellow-20) !important;
}
.bg-yellow-30 {
  background-color: var(--mg-color-yellow-30) !important;
}
.bg-yellow-40 {
  background-color: var(--mg-color-yellow-40) !important;
}
.bg-yellow-50 {
  background-color: var(--mg-color-yellow-50) !important;
}
.bg-yellow-60 {
  background-color: var(--mg-color-yellow-60) !important;
}

.bg-orange-10 {
  background-color: var(--mg-color-orange-10) !important;
}
.bg-orange-20 {
  background-color: var(--mg-color-orange-20) !important;
}
.bg-orange-30 {
  background-color: var(--mg-color-orange-30) !important;
}
.bg-orange-40 {
  background-color: var(--mg-color-orange-40) !important;
}
.bg-orange-50 {
  background-color: var(--mg-color-orange-50) !important;
}
.bg-orange-60 {
  background-color: var(--mg-color-orange-60) !important;
}

.bg-blue-10 {
  background-color: var(--mg-color-blue-10) !important;
}
.bg-blue-20 {
  background-color: var(--mg-color-blue-20) !important;
}
.bg-blue-30 {
  background-color: var(--mg-color-blue-30) !important;
}
.bg-blue-40 {
  background-color: var(--mg-color-blue-40) !important;
}
.bg-blue-50 {
  background-color: var(--mg-color-blue-50) !important;
}
.bg-blue-60 {
  background-color: var(--mg-color-blue-60) !important;
}

.bg-cyan-10 {
  background-color: var(--mg-color-cyan-10) !important;
}
.bg-cyan-20 {
  background-color: var(--mg-color-cyan-20) !important;
}
.bg-cyan-30 {
  background-color: var(--mg-color-cyan-30) !important;
}
.bg-cyan-40 {
  background-color: var(--mg-color-cyan-40) !important;
}
.bg-cyan-50 {
  background-color: var(--mg-color-cyan-50) !important;
}

.bg-salad-30 {
  background-color: var(--mg-color-salad-30) !important;
}
.bg-salad-40 {
  background-color: var(--mg-color-salad-40) !important;
}

.bg-pink-30 {
  background-color: var(--mg-color-pink-30) !important;
}
.bg-pink-40 {
  background-color: var(--mg-color-pink-40) !important;
}

.bg-eggplant-30 {
  background-color: var(--mg-color-eggplant-30) !important;
}
.bg-eggplant-40 {
  background-color: var(--mg-color-eggplant-40) !important;
}

.bg-light {
  background-color: var(--mg-color-light) !important;
}