@font-face {
  font-family: 'icomoon';
  src:
    url('../icomoon/icomoon.ttf?v8b4kl') format('truetype'),
    url('../icomoon/icomoon.woff?v8b4kl') format('woff'),
    url('../icomoon/icomoon.svg?v8b4kl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='mg-icon-'],
[class*=' mg-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mg-icon-robot:before {
  content: '\e96b';
}
.mg-icon-smile:before {
  content: '\e96c';
}
.mg-icon-sort:before {
  content: '\e96d';
}
.mg-icon-intersection:before {
  content: '\e96e';
}
.mg-icon-location:before {
  content: '\e96f';
}
.mg-icon-neutral:before {
  content: '\e970';
}
.mg-icon-setting-2:before {
  content: '\e900';
}
.mg-icon-happy:before {
  content: '\e901';
}
.mg-icon-star-fill:before {
  content: '\e902';
}
.mg-icon-star-outline:before {
  content: '\e903';
}
.mg-icon-sun:before {
  content: '\e904';
}
.mg-icon-support:before {
  content: '\e905';
}
.mg-icon-system:before {
  content: '\e906';
}
.mg-icon-table-square:before {
  content: '\e907';
}
.mg-icon-text-square:before {
  content: '\e908';
}
.mg-icon-thumb-down:before {
  content: '\e909';
}
.mg-icon-thumb-up:before {
  content: '\e90a';
}
.mg-icon-time:before {
  content: '\e90b';
}
.mg-icon-top-product:before {
  content: '\e90c';
}
.mg-icon-upload:before {
  content: '\e90d';
}
.mg-icon-user:before {
  content: '\e90e';
}
.mg-icon-version:before {
  content: '\e90f';
}
.mg-icon-wallet:before {
  content: '\e910';
}
.mg-icon-wand:before {
  content: '\e911';
}
.mg-icon-warning:before {
  content: '\e912';
}
.mg-icon-write-answer:before {
  content: '\e913';
}
.mg-icon-add:before {
  content: '\e914';
}
.mg-icon-analytic:before {
  content: '\e915';
}
.mg-icon-arrow-right-up:before {
  content: '\e916';
}
.mg-icon-auction:before {
  content: '\e917';
}
.mg-icon-blog:before {
  content: '\e918';
}
.mg-icon-book:before {
  content: '\e919';
}
.mg-icon-bookmark:before {
  content: '\e91a';
}
.mg-icon-box:before {
  content: '\e91b';
}
.mg-icon-branch:before {
  content: '\e91c';
}
.mg-icon-budget-over:before {
  content: '\e91d';
}
.mg-icon-calculator:before {
  content: '\e91e';
}
.mg-icon-calendar:before {
  content: '\e91f';
}
.mg-icon-call:before {
  content: '\e920';
}
.mg-icon-camera:before {
  content: '\e921';
}
.mg-icon-card:before {
  content: '\e922';
}
.mg-icon-cards:before {
  content: '\e923';
}
.mg-icon-chart:before {
  content: '\e924';
}
.mg-icon-chart-square:before {
  content: '\e925';
}
.mg-icon-check:before {
  content: '\e926';
}
.mg-icon-chevron-down:before {
  content: '\e927';
}
.mg-icon-chevron-left:before {
  content: '\e928';
}
.mg-icon-chevron-right:before {
  content: '\e929';
}
.mg-icon-chevron-up:before {
  content: '\e92a';
}
.mg-icon-close:before {
  content: '\e92b';
}
.mg-icon-close-square:before {
  content: '\e92c';
}
.mg-icon-communication:before {
  content: '\e92d';
}
.mg-icon-copy:before {
  content: '\e92e';
}
.mg-icon-crown-fill:before {
  content: '\e92f';
}
.mg-icon-crown-outline:before {
  content: '\e930';
}
.mg-icon-delete:before {
  content: '\e931';
}
.mg-icon-delivery:before {
  content: '\e932';
}
.mg-icon-discount:before {
  content: '\e933';
}
.mg-icon-document:before {
  content: '\e934';
}
.mg-icon-dots:before {
  content: '\e935';
}
.mg-icon-double-down:before {
  content: '\e936';
}
.mg-icon-double-left:before {
  content: '\e937';
}
.mg-icon-double-right:before {
  content: '\e938';
}
.mg-icon-double-up:before {
  content: '\e939';
}
.mg-icon-download:before {
  content: '\e93a';
}
.mg-icon-draw:before {
  content: '\e93b';
}
.mg-icon-edit:before {
  content: '\e93c';
}
.mg-icon-education:before {
  content: '\e93d';
}
.mg-icon-eye-close:before {
  content: '\e93e';
}
.mg-icon-eye-open:before {
  content: '\e93f';
}
.mg-icon-fire:before {
  content: '\e940';
}
.mg-icon-funnel:before {
  content: '\e941';
}
.mg-icon-gift:before {
  content: '\e942';
}
.mg-icon-hand:before {
  content: '\e943';
}
.mg-icon-heart-fill:before {
  content: '\e944';
}
.mg-icon-heart-outline:before {
  content: '\e945';
}
.mg-icon-history:before {
  content: '\e946';
}
.mg-icon-image:before {
  content: '\e947';
}
.mg-icon-info:before {
  content: '\e948';
}
.mg-icon-key:before {
  content: '\e949';
}
.mg-icon-letter:before {
  content: '\e94a';
}
.mg-icon-lightning:before {
  content: '\e94b';
}
.mg-icon-limit-budget:before {
  content: '\e94c';
}
.mg-icon-link:before {
  content: '\e94d';
}
.mg-icon-link-2:before {
  content: '\e94e';
}
.mg-icon-lock:before {
  content: '\e94f';
}
.mg-icon-logout:before {
  content: '\e950';
}
.mg-icon-magic:before {
  content: '\e951';
}
.mg-icon-minus:before {
  content: '\e952';
}
.mg-icon-moon:before {
  content: '\e953';
}
.mg-icon-notification:before {
  content: '\e954';
}
.mg-icon-off:before {
  content: '\e955';
}
.mg-icon-pause-square:before {
  content: '\e956';
}
.mg-icon-period-right:before {
  content: '\e957';
}
.mg-icon-pie-chart:before {
  content: '\e958';
}
.mg-icon-pin-fill:before {
  content: '\e959';
}
.mg-icon-pin-outline:before {
  content: '\e95a';
}
.mg-icon-play-square:before {
  content: '\e95b';
}
.mg-icon-plus-square:before {
  content: '\e95c';
}
.mg-icon-profile:before {
  content: '\e95d';
}
.mg-icon-puzzle-chrome:before {
  content: '\e95e';
}
.mg-icon-question:before {
  content: '\e95f';
}
.mg-icon-range-out:before {
  content: '\e960';
}
.mg-icon-receipt:before {
  content: '\e961';
}
.mg-icon-refresh:before {
  content: '\e962';
}
.mg-icon-rocket:before {
  content: '\e963';
}
.mg-icon-rotate-left:before {
  content: '\e964';
}
.mg-icon-rotate-right:before {
  content: '\e965';
}
.mg-icon-ruble-square:before {
  content: '\e966';
}
.mg-icon-ruler:before {
  content: '\e967';
}
.mg-icon-sad:before {
  content: '\e968';
}
.mg-icon-search:before {
  content: '\e969';
}
.mg-icon-setting:before {
  content: '\e96a';
}
