.gallery-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.gallery-list {
  position: relative;
  margin-right: -12px;
  margin-left: -12px;

  @media (max-width: 767.98px) {
    margin-right: -16px;
    margin-left: -16px;
  }

  &__item {
    font-size: 16px;
    line-height: 1.4;
    padding: 0 12px;

    @media (max-width: 767.98px) {
      margin: 0 16px;
      padding: 0;
    }
  }
  &__image {
    position: relative;
    margin-bottom: 13px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
  &__field {
    margin-bottom: 13px;
  }
  &__desc {
    font-size: 0.75em;
    margin-bottom: 4px;
    color: var(--mg-gray-7);
  }
}

.owl-carousel {
  .owl-nav {
    position: absolute;
    top: -98px;
    right: 16px;
    width: 80px;
    height: 32px;

    @media (max-width: 991.98px) {
      top: -40px;
    }

    [class*='owl-'] {
      width: 32px;
      height: 32px;
      margin: 0;
      border-radius: 0;
      background: url('../../images/icons/gall-arrow-ic.svg') center no-repeat;
      background-size: 12px 20px;

      @media (max-width: 991.98px) {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff url('../../images/icons/gall-arrow-sm-ic.svg') center no-repeat;
        background-size: 6px 12px;
        box-shadow: 1px 0px 6px rgba(40, 46, 61, 0.12);
      }

      &:hover {
        background: url('../../images/icons/gall-arrow-ic.svg') center no-repeat;
        background-size: 12px 20px;

        @media (max-width: 991.98px) {
          background: url('../../images/icons/gall-arrow-sm-ic.svg') center no-repeat;
          background-size: 6px 12px;
        }
      }
    }
    .owl-prev {
      margin-right: 16px;
      transform: rotate(180deg);
    }
  }
}
