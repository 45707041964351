.justify-content-stretch {
  justify-content: stretch;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-stretch\! {
  justify-content: stretch !important;
}

.justify-content-start\! {
  justify-content: flex-start !important;
}

.justify-content-end\! {
  justify-content: flex-end !important;
}

.justify-content-center\! {
  justify-content: center !important;
}

.justify-content-between\! {
  justify-content: space-between !important;
}

.justify-content-around\! {
  justify-content: space-around !important;
}

.justify-content-evenly\! {
  justify-content: space-evenly !important;
}
