.p-0 {
  padding: 0;
}

.p-2 {
  padding: var(--mg-space-2);
}

.p-4 {
  padding: var(--mg-space-4);
}

.p-6 {
  padding: var(--mg-space-6);
}

.p-8 {
  padding: var(--mg-space-8);
}

.p-12 {
  padding: var(--mg-space-12);
}

.p-16 {
  padding: var(--mg-space-16);
}

.p-20 {
  padding: var(--mg-space-20);
}

.p-24 {
  padding: var(--mg-space-24);
}

.p-28 {
  padding: var(--mg-space-28);
}

.p-32 {
  padding: var(--mg-space-32);
}

.p-36 {
  padding: var(--mg-space-36);
}

.p-40 {
  padding: var(--mg-space-40);
}

.p-44 {
  padding: var(--mg-space-44);
}

.p-48 {
  padding: var(--mg-space-48);
}

.p-52 {
  padding: var(--mg-space-52);
}

.p-56 {
  padding: var(--mg-space-56);
}

.p-60 {
  padding: var(--mg-space-60);
}

.px-0 {
  padding-inline: 0;
}

.px-2 {
  padding-inline: var(--mg-space-2);
}

.px-4 {
  padding-inline: var(--mg-space-4);
}

.px-6 {
  padding-inline: var(--mg-space-6);
}

.px-8 {
  padding-inline: var(--mg-space-8);
}

.px-12 {
  padding-inline: var(--mg-space-12);
}

.px-16 {
  padding-inline: var(--mg-space-16);
}

.px-20 {
  padding-inline: var(--mg-space-20);
}

.px-24 {
  padding-inline: var(--mg-space-24);
}

.px-28 {
  padding-inline: var(--mg-space-28);
}

.px-32 {
  padding-inline: var(--mg-space-32);
}

.px-36 {
  padding-inline: var(--mg-space-36);
}

.px-40 {
  padding-inline: var(--mg-space-40);
}

.px-44 {
  padding-inline: var(--mg-space-44);
}

.px-48 {
  padding-inline: var(--mg-space-48);
}

.px-52 {
  padding-inline: var(--mg-space-52);
}

.px-56 {
  padding-inline: var(--mg-space-56);
}

.px-60 {
  padding-inline: var(--mg-space-60);
}

.py-0 {
  padding-block: 0;
}

.py-2 {
  padding-block: var(--mg-space-2);
}

.py-4 {
  padding-block: var(--mg-space-4);
}

.py-6 {
  padding-block: var(--mg-space-6);
}

.py-8 {
  padding-block: var(--mg-space-8);
}

.py-12 {
  padding-block: var(--mg-space-12);
}

.py-16 {
  padding-block: var(--mg-space-16);
}

.py-20 {
  padding-block: var(--mg-space-20);
}

.py-24 {
  padding-block: var(--mg-space-24);
}

.py-28 {
  padding-block: var(--mg-space-28);
}

.py-32 {
  padding-block: var(--mg-space-32);
}

.py-36 {
  padding-block: var(--mg-space-36);
}

.py-40 {
  padding-block: var(--mg-space-40);
}

.py-44 {
  padding-block: var(--mg-space-44);
}

.py-48 {
  padding-block: var(--mg-space-48);
}

.py-52 {
  padding-block: var(--mg-space-52);
}

.py-56 {
  padding-block: var(--mg-space-56);
}

.py-60 {
  padding-block: var(--mg-space-60);
}

.pt-0 {
  padding-top: 0;
}

.pt-2 {
  padding-top: var(--mg-space-2);
}

.pt-4 {
  padding-top: var(--mg-space-4);
}

.pt-6 {
  padding-top: var(--mg-space-6);
}

.pt-8 {
  padding-top: var(--mg-space-8);
}

.pt-12 {
  padding-top: var(--mg-space-12);
}

.pt-16 {
  padding-top: var(--mg-space-16);
}

.pt-20 {
  padding-top: var(--mg-space-20);
}

.pt-24 {
  padding-top: var(--mg-space-24);
}

.pt-28 {
  padding-top: var(--mg-space-28);
}

.pt-32 {
  padding-top: var(--mg-space-32);
}

.pt-36 {
  padding-top: var(--mg-space-36);
}

.pt-40 {
  padding-top: var(--mg-space-40);
}

.pt-44 {
  padding-top: var(--mg-space-44);
}

.pt-48 {
  padding-top: var(--mg-space-48);
}

.pt-52 {
  padding-top: var(--mg-space-52);
}

.pt-56 {
  padding-top: var(--mg-space-56);
}

.pt-60 {
  padding-top: var(--mg-space-60);
}

.pr-0 {
  padding-right: 0;
}

.pr-2 {
  padding-right: var(--mg-space-2);
}

.pr-4 {
  padding-right: var(--mg-space-4);
}

.pr-6 {
  padding-right: var(--mg-space-6);
}

.pr-8 {
  padding-right: var(--mg-space-8);
}

.pr-12 {
  padding-right: var(--mg-space-12);
}

.pr-16 {
  padding-right: var(--mg-space-16);
}

.pr-20 {
  padding-right: var(--mg-space-20);
}

.pr-24 {
  padding-right: var(--mg-space-24);
}

.pr-28 {
  padding-right: var(--mg-space-28);
}

.pr-32 {
  padding-right: var(--mg-space-32);
}

.pr-36 {
  padding-right: var(--mg-space-36);
}

.pr-40 {
  padding-right: var(--mg-space-40);
}

.pr-44 {
  padding-right: var(--mg-space-44);
}

.pr-48 {
  padding-right: var(--mg-space-48);
}

.pr-52 {
  padding-right: var(--mg-space-52);
}

.pr-56 {
  padding-right: var(--mg-space-56);
}

.pr-60 {
  padding-right: var(--mg-space-60);
}

.pb-0 {
  padding-bottom: 0;
}

.pb-2 {
  padding-bottom: var(--mg-space-2);
}

.pb-4 {
  padding-bottom: var(--mg-space-4);
}

.pb-6 {
  padding-bottom: var(--mg-space-6);
}

.pb-8 {
  padding-bottom: var(--mg-space-8);
}

.pb-12 {
  padding-bottom: var(--mg-space-12);
}

.pb-16 {
  padding-bottom: var(--mg-space-16);
}

.pb-20 {
  padding-bottom: var(--mg-space-20);
}

.pb-24 {
  padding-bottom: var(--mg-space-24);
}

.pb-28 {
  padding-bottom: var(--mg-space-28);
}

.pb-32 {
  padding-bottom: var(--mg-space-32);
}

.pb-36 {
  padding-bottom: var(--mg-space-36);
}

.pb-40 {
  padding-bottom: var(--mg-space-40);
}

.pb-44 {
  padding-bottom: var(--mg-space-44);
}

.pb-48 {
  padding-bottom: var(--mg-space-48);
}

.pb-52 {
  padding-bottom: var(--mg-space-52);
}

.pb-56 {
  padding-bottom: var(--mg-space-56);
}

.pb-60 {
  padding-bottom: var(--mg-space-60);
}

.pl-0 {
  padding-left: 0;
}

.pl-2 {
  padding-left: var(--mg-space-2);
}

.pl-4 {
  padding-left: var(--mg-space-4);
}

.pl-6 {
  padding-left: var(--mg-space-6);
}

.pl-8 {
  padding-left: var(--mg-space-8);
}

.pl-12 {
  padding-left: var(--mg-space-12);
}

.pl-16 {
  padding-left: var(--mg-space-16);
}

.pl-20 {
  padding-left: var(--mg-space-20);
}

.pl-24 {
  padding-left: var(--mg-space-24);
}

.pl-28 {
  padding-left: var(--mg-space-28);
}

.pl-32 {
  padding-left: var(--mg-space-32);
}

.pl-36 {
  padding-left: var(--mg-space-36);
}

.pl-40 {
  padding-left: var(--mg-space-40);
}

.pl-44 {
  padding-left: var(--mg-space-44);
}

.pl-48 {
  padding-left: var(--mg-space-48);
}

.pl-52 {
  padding-left: var(--mg-space-52);
}

.pl-56 {
  padding-left: var(--mg-space-56);
}

.pl-60 {
  padding-left: var(--mg-space-60);
}

.p-0\! {
  padding: 0 !important;
}

.p-2\! {
  padding: var(--mg-space-2) !important;
}

.p-4\! {
  padding: var(--mg-space-4) !important;
}

.p-6\! {
  padding: var(--mg-space-6) !important;
}

.p-8\! {
  padding: var(--mg-space-8) !important;
}

.p-12\! {
  padding: var(--mg-space-12) !important;
}

.p-16\! {
  padding: var(--mg-space-16) !important;
}

.p-20\! {
  padding: var(--mg-space-20) !important;
}

.p-24\! {
  padding: var(--mg-space-24) !important;
}

.p-28\! {
  padding: var(--mg-space-28) !important;
}

.p-32\! {
  padding: var(--mg-space-32) !important;
}

.p-36\! {
  padding: var(--mg-space-36) !important;
}

.p-40\! {
  padding: var(--mg-space-40) !important;
}

.p-44\! {
  padding: var(--mg-space-44) !important;
}

.p-48\! {
  padding: var(--mg-space-48) !important;
}

.p-52\! {
  padding: var(--mg-space-52) !important;
}

.p-56\! {
  padding: var(--mg-space-56) !important;
}

.p-60\! {
  padding: var(--mg-space-60) !important;
}

.px-0\! {
  padding-inline: 0 !important;
}

.px-2\! {
  padding-inline: var(--mg-space-2) !important;
}

.px-4\! {
  padding-inline: var(--mg-space-4) !important;
}

.px-6\! {
  padding-inline: var(--mg-space-6) !important;
}

.px-8\! {
  padding-inline: var(--mg-space-8) !important;
}

.px-12\! {
  padding-inline: var(--mg-space-12) !important;
}

.px-16\! {
  padding-inline: var(--mg-space-16) !important;
}

.px-20\! {
  padding-inline: var(--mg-space-20) !important;
}

.px-24\! {
  padding-inline: var(--mg-space-24) !important;
}

.px-28\! {
  padding-inline: var(--mg-space-28) !important;
}

.px-32\! {
  padding-inline: var(--mg-space-32) !important;
}

.px-36\! {
  padding-inline: var(--mg-space-36) !important;
}

.px-40\! {
  padding-inline: var(--mg-space-40) !important;
}

.px-44\! {
  padding-inline: var(--mg-space-44) !important;
}

.px-48\! {
  padding-inline: var(--mg-space-48) !important;
}

.px-52\! {
  padding-inline: var(--mg-space-52) !important;
}

.px-56\! {
  padding-inline: var(--mg-space-56) !important;
}

.px-60\! {
  padding-inline: var(--mg-space-60) !important;
}

.py-0\! {
  padding-block: 0 !important;
}

.py-2\! {
  padding-block: var(--mg-space-2) !important;
}

.py-4\! {
  padding-block: var(--mg-space-4) !important;
}

.py-6\! {
  padding-block: var(--mg-space-6) !important;
}

.py-8\! {
  padding-block: var(--mg-space-8) !important;
}

.py-12\! {
  padding-block: var(--mg-space-12) !important;
}

.py-16\! {
  padding-block: var(--mg-space-16) !important;
}

.py-20\! {
  padding-block: var(--mg-space-20) !important;
}

.py-24\! {
  padding-block: var(--mg-space-24) !important;
}

.py-28\! {
  padding-block: var(--mg-space-28) !important;
}

.py-32\! {
  padding-block: var(--mg-space-32) !important;
}

.py-36\! {
  padding-block: var(--mg-space-36) !important;
}

.py-40\! {
  padding-block: var(--mg-space-40) !important;
}

.py-44\! {
  padding-block: var(--mg-space-44) !important;
}

.py-48\! {
  padding-block: var(--mg-space-48) !important;
}

.py-52\! {
  padding-block: var(--mg-space-52) !important;
}

.py-56\! {
  padding-block: var(--mg-space-56) !important;
}

.py-60\! {
  padding-block: var(--mg-space-60) !important;
}

.pt-0\! {
  padding-top: 0 !important;
}

.pt-2\! {
  padding-top: var(--mg-space-2) !important;
}

.pt-4\! {
  padding-top: var(--mg-space-4) !important;
}

.pt-6\! {
  padding-top: var(--mg-space-6) !important;
}

.pt-8\! {
  padding-top: var(--mg-space-8) !important;
}

.pt-12\! {
  padding-top: var(--mg-space-12) !important;
}

.pt-16\! {
  padding-top: var(--mg-space-16) !important;
}

.pt-20\! {
  padding-top: var(--mg-space-20) !important;
}

.pt-24\! {
  padding-top: var(--mg-space-24) !important;
}

.pt-28\! {
  padding-top: var(--mg-space-28) !important;
}

.pt-32\! {
  padding-top: var(--mg-space-32) !important;
}

.pt-36\! {
  padding-top: var(--mg-space-36) !important;
}

.pt-40\! {
  padding-top: var(--mg-space-40) !important;
}

.pt-44\! {
  padding-top: var(--mg-space-44) !important;
}

.pt-48\! {
  padding-top: var(--mg-space-48) !important;
}

.pt-52\! {
  padding-top: var(--mg-space-52) !important;
}

.pt-56\! {
  padding-top: var(--mg-space-56) !important;
}

.pt-60\! {
  padding-top: var(--mg-space-60) !important;
}

.pr-0\! {
  padding-right: 0 !important;
}

.pr-2\! {
  padding-right: var(--mg-space-2) !important;
}

.pr-4\! {
  padding-right: var(--mg-space-4) !important;
}

.pr-6\! {
  padding-right: var(--mg-space-6) !important;
}

.pr-8\! {
  padding-right: var(--mg-space-8) !important;
}

.pr-12\! {
  padding-right: var(--mg-space-12) !important;
}

.pr-16\! {
  padding-right: var(--mg-space-16) !important;
}

.pr-20\! {
  padding-right: var(--mg-space-20) !important;
}

.pr-24\! {
  padding-right: var(--mg-space-24) !important;
}

.pr-28\! {
  padding-right: var(--mg-space-28) !important;
}

.pr-32\! {
  padding-right: var(--mg-space-32) !important;
}

.pr-36\! {
  padding-right: var(--mg-space-36) !important;
}

.pr-40\! {
  padding-right: var(--mg-space-40) !important;
}

.pr-44\! {
  padding-right: var(--mg-space-44) !important;
}

.pr-48\! {
  padding-right: var(--mg-space-48) !important;
}

.pr-52\! {
  padding-right: var(--mg-space-52) !important;
}

.pr-56\! {
  padding-right: var(--mg-space-56) !important;
}

.pr-60\! {
  padding-right: var(--mg-space-60) !important;
}

.pb-0\! {
  padding-bottom: 0 !important;
}

.pb-2\! {
  padding-bottom: var(--mg-space-2) !important;
}

.pb-4\! {
  padding-bottom: var(--mg-space-4) !important;
}

.pb-6\! {
  padding-bottom: var(--mg-space-6) !important;
}

.pb-8\! {
  padding-bottom: var(--mg-space-8) !important;
}

.pb-12\! {
  padding-bottom: var(--mg-space-12) !important;
}

.pb-16\! {
  padding-bottom: var(--mg-space-16) !important;
}

.pb-20\! {
  padding-bottom: var(--mg-space-20) !important;
}

.pb-24\! {
  padding-bottom: var(--mg-space-24) !important;
}

.pb-28\! {
  padding-bottom: var(--mg-space-28) !important;
}

.pb-32\! {
  padding-bottom: var(--mg-space-32) !important;
}

.pb-36\! {
  padding-bottom: var(--mg-space-36) !important;
}

.pb-40\! {
  padding-bottom: var(--mg-space-40) !important;
}

.pb-44\! {
  padding-bottom: var(--mg-space-44) !important;
}

.pb-48\! {
  padding-bottom: var(--mg-space-48) !important;
}

.pb-52\! {
  padding-bottom: var(--mg-space-52) !important;
}

.pb-56\! {
  padding-bottom: var(--mg-space-56) !important;
}

.pb-60\! {
  padding-bottom: var(--mg-space-60) !important;
}

.pl-0\! {
  padding-left: 0 !important;
}

.pl-2\! {
  padding-left: var(--mg-space-2) !important;
}

.pl-4\! {
  padding-left: var(--mg-space-4) !important;
}

.pl-6\! {
  padding-left: var(--mg-space-6) !important;
}

.pl-8\! {
  padding-left: var(--mg-space-8) !important;
}

.pl-12\! {
  padding-left: var(--mg-space-12) !important;
}

.pl-16\! {
  padding-left: var(--mg-space-16) !important;
}

.pl-20\! {
  padding-left: var(--mg-space-20) !important;
}

.pl-24\! {
  padding-left: var(--mg-space-24) !important;
}

.pl-28\! {
  padding-left: var(--mg-space-28) !important;
}

.pl-32\! {
  padding-left: var(--mg-space-32) !important;
}

.pl-36\! {
  padding-left: var(--mg-space-36) !important;
}

.pl-40\! {
  padding-left: var(--mg-space-40) !important;
}

.pl-44\! {
  padding-left: var(--mg-space-44) !important;
}

.pl-48\! {
  padding-left: var(--mg-space-48) !important;
}

.pl-52\! {
  padding-left: var(--mg-space-52) !important;
}

.pl-56\! {
  padding-left: var(--mg-space-56) !important;
}

.pl-60\! {
  padding-left: var(--mg-space-60) !important;
}
