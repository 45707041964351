.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: var(--mg-space-2);
}

.m-4 {
  margin: var(--mg-space-4);
}

.m-6 {
  margin: var(--mg-space-6);
}

.m-8 {
  margin: var(--mg-space-8);
}

.m-12 {
  margin: var(--mg-space-12);
}

.m-16 {
  margin: var(--mg-space-16);
}

.m-20 {
  margin: var(--mg-space-20);
}

.m-24 {
  margin: var(--mg-space-24);
}

.m-28 {
  margin: var(--mg-space-28);
}

.m-32 {
  margin: var(--mg-space-32);
}

.m-36 {
  margin: var(--mg-space-36);
}

.m-40 {
  margin: var(--mg-space-40);
}

.m-44 {
  margin: var(--mg-space-44);
}

.m-48 {
  margin: var(--mg-space-48);
}

.m-52 {
  margin: var(--mg-space-52);
}

.m-56 {
  margin: var(--mg-space-56);
}

.m-60 {
  margin: var(--mg-space-60);
}

.mx-auto {
  margin-inline: auto;
}

.mx-0 {
  margin-inline: 0;
}

.mx-2 {
  margin-inline: var(--mg-space-2);
}

.mx-4 {
  margin-inline: var(--mg-space-4);
}

.mx-6 {
  margin-inline: var(--mg-space-6);
}

.mx-8 {
  margin-inline: var(--mg-space-8);
}

.mx-12 {
  margin-inline: var(--mg-space-12);
}

.mx-16 {
  margin-inline: var(--mg-space-16);
}

.mx-20 {
  margin-inline: var(--mg-space-20);
}

.mx-24 {
  margin-inline: var(--mg-space-24);
}

.mx-28 {
  margin-inline: var(--mg-space-28);
}

.mx-32 {
  margin-inline: var(--mg-space-32);
}

.mx-36 {
  margin-inline: var(--mg-space-36);
}

.mx-40 {
  margin-inline: var(--mg-space-40);
}

.mx-44 {
  margin-inline: var(--mg-space-44);
}

.mx-48 {
  margin-inline: var(--mg-space-48);
}

.mx-52 {
  margin-inline: var(--mg-space-52);
}

.mx-56 {
  margin-inline: var(--mg-space-56);
}

.mx-60 {
  margin-inline: var(--mg-space-60);
}

.my-auto {
  margin-block: auto;
}

.my-0 {
  margin-block: 0;
}

.my-2 {
  margin-block: var(--mg-space-2);
}

.my-4 {
  margin-block: var(--mg-space-4);
}

.my-6 {
  margin-block: var(--mg-space-6);
}

.my-8 {
  margin-block: var(--mg-space-8);
}

.my-12 {
  margin-block: var(--mg-space-12);
}

.my-16 {
  margin-block: var(--mg-space-16);
}

.my-20 {
  margin-block: var(--mg-space-20);
}

.my-24 {
  margin-block: var(--mg-space-24);
}

.my-28 {
  margin-block: var(--mg-space-28);
}

.my-32 {
  margin-block: var(--mg-space-32);
}

.my-36 {
  margin-block: var(--mg-space-36);
}

.my-40 {
  margin-block: var(--mg-space-40);
}

.my-44 {
  margin-block: var(--mg-space-44);
}

.my-48 {
  margin-block: var(--mg-space-48);
}

.my-52 {
  margin-block: var(--mg-space-52);
}

.my-56 {
  margin-block: var(--mg-space-56);
}

.my-60 {
  margin-block: var(--mg-space-60);
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: var(--mg-space-2);
}

.mt-4 {
  margin-top: var(--mg-space-4);
}

.mt-6 {
  margin-top: var(--mg-space-6);
}

.mt-8 {
  margin-top: var(--mg-space-8);
}

.mt-12 {
  margin-top: var(--mg-space-12);
}

.mt-16 {
  margin-top: var(--mg-space-16);
}

.mt-20 {
  margin-top: var(--mg-space-20);
}

.mt-24 {
  margin-top: var(--mg-space-24);
}

.mt-28 {
  margin-top: var(--mg-space-28);
}

.mt-32 {
  margin-top: var(--mg-space-32);
}

.mt-36 {
  margin-top: var(--mg-space-36);
}

.mt-40 {
  margin-top: var(--mg-space-40);
}

.mt-44 {
  margin-top: var(--mg-space-44);
}

.mt-48 {
  margin-top: var(--mg-space-48);
}

.mt-52 {
  margin-top: var(--mg-space-52);
}

.mt-56 {
  margin-top: var(--mg-space-56);
}

.mt-60 {
  margin-top: var(--mg-space-60);
}

.mr-auto {
  margin-right: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-2 {
  margin-right: var(--mg-space-2);
}

.mr-4 {
  margin-right: var(--mg-space-4);
}

.mr-6 {
  margin-right: var(--mg-space-6);
}

.mr-8 {
  margin-right: var(--mg-space-8);
}

.mr-12 {
  margin-right: var(--mg-space-12);
}

.mr-16 {
  margin-right: var(--mg-space-16);
}

.mr-20 {
  margin-right: var(--mg-space-20);
}

.mr-24 {
  margin-right: var(--mg-space-24);
}

.mr-28 {
  margin-right: var(--mg-space-28);
}

.mr-32 {
  margin-right: var(--mg-space-32);
}

.mr-36 {
  margin-right: var(--mg-space-36);
}

.mr-40 {
  margin-right: var(--mg-space-40);
}

.mr-44 {
  margin-right: var(--mg-space-44);
}

.mr-48 {
  margin-right: var(--mg-space-48);
}

.mr-52 {
  margin-right: var(--mg-space-52);
}

.mr-56 {
  margin-right: var(--mg-space-56);
}

.mr-60 {
  margin-right: var(--mg-space-60);
}

.mb-auto {
  margin-bottom: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: var(--mg-space-2);
}

.mb-4 {
  margin-bottom: var(--mg-space-4);
}

.mb-6 {
  margin-bottom: var(--mg-space-6);
}

.mb-8 {
  margin-bottom: var(--mg-space-8);
}

.mb-12 {
  margin-bottom: var(--mg-space-12);
}

.mb-16 {
  margin-bottom: var(--mg-space-16);
}

.mb-20 {
  margin-bottom: var(--mg-space-20);
}

.mb-24 {
  margin-bottom: var(--mg-space-24);
}

.mb-28 {
  margin-bottom: var(--mg-space-28);
}

.mb-32 {
  margin-bottom: var(--mg-space-32);
}

.mb-36 {
  margin-bottom: var(--mg-space-36);
}

.mb-40 {
  margin-bottom: var(--mg-space-40);
}

.mb-44 {
  margin-bottom: var(--mg-space-44);
}

.mb-48 {
  margin-bottom: var(--mg-space-48);
}

.mb-52 {
  margin-bottom: var(--mg-space-52);
}

.mb-56 {
  margin-bottom: var(--mg-space-56);
}

.mb-60 {
  margin-bottom: var(--mg-space-60);
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-2 {
  margin-left: var(--mg-space-2);
}

.ml-4 {
  margin-left: var(--mg-space-4);
}

.ml-6 {
  margin-left: var(--mg-space-6);
}

.ml-8 {
  margin-left: var(--mg-space-8);
}

.ml-12 {
  margin-left: var(--mg-space-12);
}

.ml-16 {
  margin-left: var(--mg-space-16);
}

.ml-20 {
  margin-left: var(--mg-space-20);
}

.ml-24 {
  margin-left: var(--mg-space-24);
}

.ml-28 {
  margin-left: var(--mg-space-28);
}

.ml-32 {
  margin-left: var(--mg-space-32);
}

.ml-36 {
  margin-left: var(--mg-space-36);
}

.ml-40 {
  margin-left: var(--mg-space-40);
}

.ml-44 {
  margin-left: var(--mg-space-44);
}

.ml-48 {
  margin-left: var(--mg-space-48);
}

.ml-52 {
  margin-left: var(--mg-space-52);
}

.ml-56 {
  margin-left: var(--mg-space-56);
}

.ml-60 {
  margin-left: var(--mg-space-60);
}

.m-auto\! {
  margin: auto !important;
}

.m-0\! {
  margin: 0 !important;
}

.m-2\! {
  margin: var(--mg-space-2) !important;
}

.m-4\! {
  margin: var(--mg-space-4) !important;
}

.m-6\! {
  margin: var(--mg-space-6) !important;
}

.m-8\! {
  margin: var(--mg-space-8) !important;
}

.m-12\! {
  margin: var(--mg-space-12) !important;
}

.m-16\! {
  margin: var(--mg-space-16) !important;
}

.m-20\! {
  margin: var(--mg-space-20) !important;
}

.m-24\! {
  margin: var(--mg-space-24) !important;
}

.m-28\! {
  margin: var(--mg-space-28) !important;
}

.m-32\! {
  margin: var(--mg-space-32) !important;
}

.m-36\! {
  margin: var(--mg-space-36) !important;
}

.m-40\! {
  margin: var(--mg-space-40) !important;
}

.m-44\! {
  margin: var(--mg-space-44) !important;
}

.m-48\! {
  margin: var(--mg-space-48) !important;
}

.m-52\! {
  margin: var(--mg-space-52) !important;
}

.m-56\! {
  margin: var(--mg-space-56) !important;
}

.m-60\! {
  margin: var(--mg-space-60) !important;
}

.mx-auto\! {
  margin-inline: auto !important;
}

.mx-0\! {
  margin-inline: 0 !important;
}

.mx-2\! {
  margin-inline: var(--mg-space-2) !important;
}

.mx-4\! {
  margin-inline: var(--mg-space-4) !important;
}

.mx-6\! {
  margin-inline: var(--mg-space-6) !important;
}

.mx-8\! {
  margin-inline: var(--mg-space-8) !important;
}

.mx-12\! {
  margin-inline: var(--mg-space-12) !important;
}

.mx-16\! {
  margin-inline: var(--mg-space-16) !important;
}

.mx-20\! {
  margin-inline: var(--mg-space-20) !important;
}

.mx-24\! {
  margin-inline: var(--mg-space-24) !important;
}

.mx-28\! {
  margin-inline: var(--mg-space-28) !important;
}

.mx-32\! {
  margin-inline: var(--mg-space-32) !important;
}

.mx-36\! {
  margin-inline: var(--mg-space-36) !important;
}

.mx-40\! {
  margin-inline: var(--mg-space-40) !important;
}

.mx-44\! {
  margin-inline: var(--mg-space-44) !important;
}

.mx-48\! {
  margin-inline: var(--mg-space-48) !important;
}

.mx-52\! {
  margin-inline: var(--mg-space-52) !important;
}

.mx-56\! {
  margin-inline: var(--mg-space-56) !important;
}

.mx-60\! {
  margin-inline: var(--mg-space-60) !important;
}

.my-auto\! {
  margin-block: auto !important;
}

.my-0\! {
  margin-block: 0 !important;
}

.my-2\! {
  margin-block: var(--mg-space-2) !important;
}

.my-4\! {
  margin-block: var(--mg-space-4) !important;
}

.my-6\! {
  margin-block: var(--mg-space-6) !important;
}

.my-8\! {
  margin-block: var(--mg-space-8) !important;
}

.my-12\! {
  margin-block: var(--mg-space-12) !important;
}

.my-16\! {
  margin-block: var(--mg-space-16) !important;
}

.my-20\! {
  margin-block: var(--mg-space-20) !important;
}

.my-24\! {
  margin-block: var(--mg-space-24) !important;
}

.my-28\! {
  margin-block: var(--mg-space-28) !important;
}

.my-32\! {
  margin-block: var(--mg-space-32) !important;
}

.my-36\! {
  margin-block: var(--mg-space-36) !important;
}

.my-40\! {
  margin-block: var(--mg-space-40) !important;
}

.my-44\! {
  margin-block: var(--mg-space-44) !important;
}

.my-48\! {
  margin-block: var(--mg-space-48) !important;
}

.my-52\! {
  margin-block: var(--mg-space-52) !important;
}

.my-56\! {
  margin-block: var(--mg-space-56) !important;
}

.my-60\! {
  margin-block: var(--mg-space-60) !important;
}

.mt-auto\! {
  margin-top: auto !important;
}

.mt-0\! {
  margin-top: 0 !important;
}

.mt-2\! {
  margin-top: var(--mg-space-2) !important;
}

.mt-4\! {
  margin-top: var(--mg-space-4) !important;
}

.mt-6\! {
  margin-top: var(--mg-space-6) !important;
}

.mt-8\! {
  margin-top: var(--mg-space-8) !important;
}

.mt-12\! {
  margin-top: var(--mg-space-12) !important;
}

.mt-16\! {
  margin-top: var(--mg-space-16) !important;
}

.mt-20\! {
  margin-top: var(--mg-space-20) !important;
}

.mt-24\! {
  margin-top: var(--mg-space-24) !important;
}

.mt-28\! {
  margin-top: var(--mg-space-28) !important;
}

.mt-32\! {
  margin-top: var(--mg-space-32) !important;
}

.mt-36\! {
  margin-top: var(--mg-space-36) !important;
}

.mt-40\! {
  margin-top: var(--mg-space-40) !important;
}

.mt-44\! {
  margin-top: var(--mg-space-44) !important;
}

.mt-48\! {
  margin-top: var(--mg-space-48) !important;
}

.mt-52\! {
  margin-top: var(--mg-space-52) !important;
}

.mt-56\! {
  margin-top: var(--mg-space-56) !important;
}

.mt-60\! {
  margin-top: var(--mg-space-60) !important;
}

.mr-auto\! {
  margin-right: auto !important;
}

.mr-0\! {
  margin-right: 0 !important;
}

.mr-2\! {
  margin-right: var(--mg-space-2) !important;
}

.mr-4\! {
  margin-right: var(--mg-space-4) !important;
}

.mr-6\! {
  margin-right: var(--mg-space-6) !important;
}

.mr-8\! {
  margin-right: var(--mg-space-8) !important;
}

.mr-12\! {
  margin-right: var(--mg-space-12) !important;
}

.mr-16\! {
  margin-right: var(--mg-space-16) !important;
}

.mr-20\! {
  margin-right: var(--mg-space-20) !important;
}

.mr-24\! {
  margin-right: var(--mg-space-24) !important;
}

.mr-28\! {
  margin-right: var(--mg-space-28) !important;
}

.mr-32\! {
  margin-right: var(--mg-space-32) !important;
}

.mr-36\! {
  margin-right: var(--mg-space-36) !important;
}

.mr-40\! {
  margin-right: var(--mg-space-40) !important;
}

.mr-44\! {
  margin-right: var(--mg-space-44) !important;
}

.mr-48\! {
  margin-right: var(--mg-space-48) !important;
}

.mr-52\! {
  margin-right: var(--mg-space-52) !important;
}

.mr-56\! {
  margin-right: var(--mg-space-56) !important;
}

.mr-60\! {
  margin-right: var(--mg-space-60) !important;
}

.mb-auto\! {
  margin-bottom: auto !important;
}

.mb-0\! {
  margin-bottom: 0 !important;
}

.mb-2\! {
  margin-bottom: var(--mg-space-2) !important;
}

.mb-4\! {
  margin-bottom: var(--mg-space-4) !important;
}

.mb-6\! {
  margin-bottom: var(--mg-space-6) !important;
}

.mb-8\! {
  margin-bottom: var(--mg-space-8) !important;
}

.mb-12\! {
  margin-bottom: var(--mg-space-12) !important;
}

.mb-16\! {
  margin-bottom: var(--mg-space-16) !important;
}

.mb-20\! {
  margin-bottom: var(--mg-space-20) !important;
}

.mb-24\! {
  margin-bottom: var(--mg-space-24) !important;
}

.mb-28\! {
  margin-bottom: var(--mg-space-28) !important;
}

.mb-32\! {
  margin-bottom: var(--mg-space-32) !important;
}

.mb-36\! {
  margin-bottom: var(--mg-space-36) !important;
}

.mb-40\! {
  margin-bottom: var(--mg-space-40) !important;
}

.mb-44\! {
  margin-bottom: var(--mg-space-44) !important;
}

.mb-48\! {
  margin-bottom: var(--mg-space-48) !important;
}

.mb-52\! {
  margin-bottom: var(--mg-space-52) !important;
}

.mb-56\! {
  margin-bottom: var(--mg-space-56) !important;
}

.mb-60\! {
  margin-bottom: var(--mg-space-60) !important;
}

.ml-auto\! {
  margin-left: auto !important;
}

.ml-0\! {
  margin-left: 0 !important;
}

.ml-2\! {
  margin-left: var(--mg-space-2) !important;
}

.ml-4\! {
  margin-left: var(--mg-space-4) !important;
}

.ml-6\! {
  margin-left: var(--mg-space-6) !important;
}

.ml-8\! {
  margin-left: var(--mg-space-8) !important;
}

.ml-12\! {
  margin-left: var(--mg-space-12) !important;
}

.ml-16\! {
  margin-left: var(--mg-space-16) !important;
}

.ml-20\! {
  margin-left: var(--mg-space-20) !important;
}

.ml-24\! {
  margin-left: var(--mg-space-24) !important;
}

.ml-28\! {
  margin-left: var(--mg-space-28) !important;
}

.ml-32\! {
  margin-left: var(--mg-space-32) !important;
}

.ml-36\! {
  margin-left: var(--mg-space-36) !important;
}

.ml-40\! {
  margin-left: var(--mg-space-40) !important;
}

.ml-44\! {
  margin-left: var(--mg-space-44) !important;
}

.ml-48\! {
  margin-left: var(--mg-space-48) !important;
}

.ml-52\! {
  margin-left: var(--mg-space-52) !important;
}

.ml-56\! {
  margin-left: var(--mg-space-56) !important;
}

.ml-60\! {
  margin-left: var(--mg-space-60) !important;
}
