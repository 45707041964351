@import 'simple-notify/dist/simple-notify.min.css';

.notifications-container {
  max-width: 400px;
}

.notify.notify--type-1 {
  padding: 22px 24px 24px 48px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background: #ffffff 24px 24px / 16px no-repeat;
  --notify-close-icon-size: 14px;

  .notify__title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--mg-blue-10);
  }

  .notify__close {
    top: 0;
    right: 0;
    width: calc(var(--notify-close-icon-size) + 16px);
    height: calc(var(--notify-close-icon-size) + 16px);
    padding: 8px;
  }

  .notify__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.38;
    white-space: pre-line;
    color: var(--mg-blue-10);

    a {
      color: var(--mg-violet-10);
    }
  }

  .notify__title + .notify__text {
    margin-top: 8px;
  }

  &.notify-autoclose::before {
    display: none;
  }

  &.notify--success {
    border-color: var(--mg-green-6);
    background-color: var(--mg-green-1);
    background-image: url('/assets/images/icons/success.svg');
  }

  &.notify--error {
    border-color: var(--mg-red-6);
    background-color: var(--mg-red-1);
    background-image: url('/assets/images/icons/error.svg');
  }
}
