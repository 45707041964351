.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-none {
  display: none;
}

.d-inline\! {
  display: inline !important;
}

.d-inline-block\! {
  display: inline-block !important;
}

.d-block\! {
  display: block !important;
}

.d-flex\! {
  display: flex !important;
}

.d-inline-flex\! {
  display: inline-flex !important;
}

.d-grid\! {
  display: grid !important;
}

.d-inline-grid\! {
  display: inline-grid !important;
}

.d-none\! {
  display: none !important;
}
