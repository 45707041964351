:root {
  --mg-space-2: .2rem;
  --mg-space-4: .4rem;
  --mg-space-6: .6rem;
  --mg-space-8: .8rem;
  --mg-space-12: 1.2rem;
  --mg-space-16: 1.6rem;
  --mg-space-20: 2rem;
  --mg-space-24: 2.4rem;
  --mg-space-28: 2.8rem;
  --mg-space-32: 3.2rem;
  --mg-space-36: 3.6rem;
  --mg-space-40: 4rem;
  --mg-space-44: 4.4rem;
  --mg-space-48: 4.8rem;
  --mg-space-52: 5.2rem;
  --mg-space-56: 5.6rem;
  --mg-space-60: 6rem;
}
