.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0\! {
  flex-shrink: 0 !important;
}

.flex-shrink-1\! {
  flex-shrink: 1 !important;
}
