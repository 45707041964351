button {
  color: #000;
}

button {
  font-family: inherit;
}

.btn {
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  display: inline-block;
  padding: 14px 16px;
  cursor: pointer;
  transition:
    background-color,
    border 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border: 1px solid transparent;
  background-color: transparent;

  @media (max-width: 767.98px) {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    padding: 13px;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  &:active {
    color: inherit;
  }

  &:disabled {
    cursor: default;
  }
}

button.dropdown-menu-primitive__item {
  line-height: 1.4;
}

.btn {
  white-space: nowrap;
}

.btn-minw {
  min-width: 188px;
}

.btn-purple {
  text-align: center;
  color: #fff;
  border-radius: 8px;
  background-color: var(--mg-violet-10);

  &:hover,
  &:active {
    color: #fff;
    border-color: darken(#933fa1, 10%);
    background-color: darken(#933fa1, 10%);
  }
}

.btn-border {
  text-align: center;
  color: var(--mg-blue-10);
  border: 1px solid var(--mg-gray-7);
  border-radius: 8px;
  background: transparent;

  &:hover,
  &:active {
    color: var(--mg-blue-10);
    background: #eff0f7;
  }
}

.btn-dark {
  text-align: center;
  color: #fff;
  border-radius: 8px;
  background-color: var(--mg-blue-10);

  &:hover,
  &:active {
    color: #fff;
    border-color: darken(#282e3d, 10%);
    background-color: darken(#282e3d, 10%);
  }
}

.btn-grey {
  text-align: center;
  color: var(--mg-blue-10);
  border-radius: 8px;
  background-color: var(--mg-gray-2);

  &:hover,
  &:active {
    color: var(--mg-blue-10);
    border-color: darken(#eff0f7, 10%);
    background-color: darken(#eff0f7, 10%);
  }
}

.btn-blue {
  text-align: center;
  color: #fff;
  border-radius: 8px;
  background-color: var(--mg-blue-8);

  &:hover,
  &:active {
    color: #fff;
    border-color: darken(#3b4a71, 10%);
    background-color: darken(#3b4a71, 10%);
  }
}

.btn-fs-sm {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  padding: 8px 10px;
}

.btn-video {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  background: url('../../images/icons/video-btn-ic.svg') center no-repeat;
  background-size: 56px 56px;
}

.btn-light-blue {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  padding: 7px 12px;
  color: var(--mg-blue-10);
  border-radius: 8px;
  background-color: var(--mg-blue-2);

  &:hover,
  &:active {
    color: var(--mg-blue-10);
    border-color: transparent;
    background-color: var(--mg-blue-1);
  }
}

.mg-button {
  white-space: nowrap;

  &.cta-button {
    font: var(--mg-font-m);
    display: inline-flex;
    margin-top: var(--mg-space-32);
    padding: var(--mg-space-12) var(--mg-space-16);

    @media (max-width: 991px) {
      margin-top: var(--mg-space-20);
    }

    @media (max-width: 575px) {
      font: var(--mg-font-s);
      padding: var(--mg-space-8) var(--mg-space-12);
    }
  }

  &:hover {
    color: var(--mg-color-light);
  }
}
