@forward 'prototyping/index';
@forward 'fonts';
@forward 'icomoon';
@forward 'mixins';
@forward 'radiuses';
@forward 'reset';
@forward 'spacings';
@forward 'themes';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;800&display=swap');
