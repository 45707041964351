.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row\! {
  flex-direction: row !important;
}

.flex-row-reverse\! {
  flex-direction: row-reverse !important;
}

.flex-column\! {
  flex-direction: column !important;
}

.flex-column-reverse\! {
  flex-direction: column-reverse !important;
}
