form {
  mg-input-primitive input, mg-label-primitive, mg-error-primitive, button {
    font-size: 13px !important;
    line-height: 1.385 !important;

    &::placeholder {
      font-size: 13px !important;
    }
  }
}
