@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-0.5 * $gutter-x);
  margin-left: calc(-0.5 * $gutter-x);
}

@mixin make-col-ready() {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc($gutter-x * 0.5);
  padding-left: calc($gutter-x * 0.5);
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(divide($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-grid-columns($columns: $grid-columns) {
  .col {
    flex: 1 0 0;
  }

  @for $i from 1 through $columns {
    .col-#{$i} {
      @include make-col($i, $columns);
    }
  }
}
