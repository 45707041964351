*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

html {
  font-size: 13px;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-family;
  line-height: 1.4;
  margin: 0;
  color: map-get($gray, "gray-10");
  background-color: map-get($gray, "gray-1");
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.heading {
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

h1 {
  @extend .heading;
  font-size: 15px;
}

h2 {
  @extend .heading;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: map-get($violet, "violet-8");
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    color: map-get($violet, "violet-9");
  }
}

img,
svg {
  vertical-align: middle;
}

p {
  margin-top: 0;
  margin-bottom: 13px;
}

ol,
ul {
  padding-left: 26px;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 13px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: 700;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

[hidden] {
  display: none !important;
}
