.fs-xxxs {
  font-size: var(--mg-fs-xxxs);
}

.fs-xxs {
  font-size: var(--mg-fs-xxs);
}

.fs-xs {
  font-size: var(--mg-fs-xs);
}

.fs-s {
  font-size: var(--mg-fs-s);
}

.fs-m {
  font-size: var(--mg-fs-m);
}

.fs-l {
  font-size: var(--mg-fs-l);
}

.fs-xl {
  font-size: var(--mg-fs-xl);
}

.fs-xxxs\! {
  font-size: var(--mg-fs-xxxs) !important;
}

.fs-xxs\! {
  font-size: var(--mg-fs-xxs) !important;
}

.fs-xs\! {
  font-size: var(--mg-fs-xs) !important;
}

.fs-s\! {
  font-size: var(--mg-fs-s) !important;
}

.fs-m\! {
  font-size: var(--mg-fs-m) !important;
}

.fs-l\! {
  font-size: var(--mg-fs-l) !important;
}

.fs-xl\! {
  font-size: var(--mg-fs-xl) !important;
}
