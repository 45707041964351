.badge {
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;

  &.badge-new {
    background: map-get($violet, 'violet-10');
    color: #fff;
  }
}
