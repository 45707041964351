.article-block {
  &__content {
    font-size: 16px;
    line-height: 1.4;

    @media (max-width: 1199.98px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      align-items: flex-start;
      flex-direction: column;

      &--reverse {
        flex-direction: column-reverse;
      }

      &-preview, &-info {
        width: 100%;
      }
    }

    &-title {
      font-size: 2em;
      font-weight: 600;
      line-height: 1.2;
      margin: 0 0 13px 0;

      @media (max-width: 1199.98px) {
        font-size: 1.4em;
      }
    }

    &-text {
      font-size: 1.25em;
      font-weight: 500;

      p {
        margin-bottom: 13px;
      }
    }

    &-desc {
      font-weight: 400;
      margin-bottom: 26px;
    }

    &-preview {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-video {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      filter: drop-shadow(4px 5px 11px rgba(40, 46, 61, 0.15));

      &.no-shadow {
        filter: none;
      }
    }
  }
}
