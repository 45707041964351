.g-0 {
  gap: 0;
}

.g-2 {
  gap: var(--mg-space-2);
}

.g-4 {
  gap: var(--mg-space-4);
}

.g-6 {
  gap: var(--mg-space-6);
}

.g-8 {
  gap: var(--mg-space-8);
}

.g-12 {
  gap: var(--mg-space-12);
}

.g-16 {
  gap: var(--mg-space-16);
}

.g-20 {
  gap: var(--mg-space-20);
}

.g-24 {
  gap: var(--mg-space-24);
}

.g-28 {
  gap: var(--mg-space-28);
}

.g-32 {
  gap: var(--mg-space-32);
}

.g-36 {
  gap: var(--mg-space-36);
}

.g-40 {
  gap: var(--mg-space-40);
}

.g-44 {
  gap: var(--mg-space-44);
}

.g-48 {
  gap: var(--mg-space-48);
}

.g-52 {
  gap: var(--mg-space-52);
}

.g-56 {
  gap: var(--mg-space-56);
}

.g-60 {
  gap: var(--mg-space-60);
}

.gx-0 {
  column-gap: 0;
}

.gx-2 {
  column-gap: var(--mg-space-2);
}

.gx-4 {
  column-gap: var(--mg-space-4);
}

.gx-6 {
  column-gap: var(--mg-space-6);
}

.gx-8 {
  column-gap: var(--mg-space-8);
}

.gx-12 {
  column-gap: var(--mg-space-12);
}

.gx-16 {
  column-gap: var(--mg-space-16);
}

.gx-20 {
  column-gap: var(--mg-space-20);
}

.gx-24 {
  column-gap: var(--mg-space-24);
}

.gx-28 {
  column-gap: var(--mg-space-28);
}

.gx-32 {
  column-gap: var(--mg-space-32);
}

.gx-36 {
  column-gap: var(--mg-space-36);
}

.gx-40 {
  column-gap: var(--mg-space-40);
}

.gx-44 {
  column-gap: var(--mg-space-44);
}

.gx-48 {
  column-gap: var(--mg-space-48);
}

.gx-52 {
  column-gap: var(--mg-space-52);
}

.gx-56 {
  column-gap: var(--mg-space-56);
}

.gx-60 {
  column-gap: var(--mg-space-60);
}

.gy-0 {
  row-gap: 0;
}

.gy-2 {
  row-gap: var(--mg-space-2);
}

.gy-4 {
  row-gap: var(--mg-space-4);
}

.gy-6 {
  row-gap: var(--mg-space-6);
}

.gy-8 {
  row-gap: var(--mg-space-8);
}

.gy-12 {
  row-gap: var(--mg-space-12);
}

.gy-16 {
  row-gap: var(--mg-space-16);
}

.gy-20 {
  row-gap: var(--mg-space-20);
}

.gy-24 {
  row-gap: var(--mg-space-24);
}

.gy-28 {
  row-gap: var(--mg-space-28);
}

.gy-32 {
  row-gap: var(--mg-space-32);
}

.gy-36 {
  row-gap: var(--mg-space-36);
}

.gy-40 {
  row-gap: var(--mg-space-40);
}

.gy-44 {
  row-gap: var(--mg-space-44);
}

.gy-48 {
  row-gap: var(--mg-space-48);
}

.gy-52 {
  row-gap: var(--mg-space-52);
}

.gy-56 {
  row-gap: var(--mg-space-56);
}

.gy-60 {
  row-gap: var(--mg-space-60);
}

.g-0\! {
  gap: 0 !important;
}

.g-2\! {
  gap: var(--mg-space-2) !important;
}

.g-4\! {
  gap: var(--mg-space-4) !important;
}

.g-6\! {
  gap: var(--mg-space-6) !important;
}

.g-8\! {
  gap: var(--mg-space-8) !important;
}

.g-12\! {
  gap: var(--mg-space-12) !important;
}

.g-16\! {
  gap: var(--mg-space-16) !important;
}

.g-20\! {
  gap: var(--mg-space-20) !important;
}

.g-24\! {
  gap: var(--mg-space-24) !important;
}

.g-28\! {
  gap: var(--mg-space-28) !important;
}

.g-32\! {
  gap: var(--mg-space-32) !important;
}

.g-36\! {
  gap: var(--mg-space-36) !important;
}

.g-40\! {
  gap: var(--mg-space-40) !important;
}

.g-44\! {
  gap: var(--mg-space-44) !important;
}

.g-48\! {
  gap: var(--mg-space-48) !important;
}

.g-52\! {
  gap: var(--mg-space-52) !important;
}

.g-56\! {
  gap: var(--mg-space-56) !important;
}

.g-60\! {
  gap: var(--mg-space-60) !important;
}

.gx-0\! {
  column-gap: 0 !important;
}

.gx-2\! {
  column-gap: var(--mg-space-2) !important;
}

.gx-4\! {
  column-gap: var(--mg-space-4) !important;
}

.gx-6\! {
  column-gap: var(--mg-space-6) !important;
}

.gx-8\! {
  column-gap: var(--mg-space-8) !important;
}

.gx-12\! {
  column-gap: var(--mg-space-12) !important;
}

.gx-16\! {
  column-gap: var(--mg-space-16) !important;
}

.gx-20\! {
  column-gap: var(--mg-space-20) !important;
}

.gx-24\! {
  column-gap: var(--mg-space-24) !important;
}

.gx-28\! {
  column-gap: var(--mg-space-28) !important;
}

.gx-32\! {
  column-gap: var(--mg-space-32) !important;
}

.gx-36\! {
  column-gap: var(--mg-space-36) !important;
}

.gx-40\! {
  column-gap: var(--mg-space-40) !important;
}

.gx-44\! {
  column-gap: var(--mg-space-44) !important;
}

.gx-48\! {
  column-gap: var(--mg-space-48) !important;
}

.gx-52\! {
  column-gap: var(--mg-space-52) !important;
}

.gx-56\! {
  column-gap: var(--mg-space-56) !important;
}

.gx-60\! {
  column-gap: var(--mg-space-60) !important;
}

.gy-0\! {
  row-gap: 0 !important;
}

.gy-2\! {
  row-gap: var(--mg-space-2) !important;
}

.gy-4\! {
  row-gap: var(--mg-space-4) !important;
}

.gy-6\! {
  row-gap: var(--mg-space-6) !important;
}

.gy-8\! {
  row-gap: var(--mg-space-8) !important;
}

.gy-12\! {
  row-gap: var(--mg-space-12) !important;
}

.gy-16\! {
  row-gap: var(--mg-space-16) !important;
}

.gy-20\! {
  row-gap: var(--mg-space-20) !important;
}

.gy-24\! {
  row-gap: var(--mg-space-24) !important;
}

.gy-28\! {
  row-gap: var(--mg-space-28) !important;
}

.gy-32\! {
  row-gap: var(--mg-space-32) !important;
}

.gy-36\! {
  row-gap: var(--mg-space-36) !important;
}

.gy-40\! {
  row-gap: var(--mg-space-40) !important;
}

.gy-44\! {
  row-gap: var(--mg-space-44) !important;
}

.gy-48\! {
  row-gap: var(--mg-space-48) !important;
}

.gy-52\! {
  row-gap: var(--mg-space-52) !important;
}

.gy-56\! {
  row-gap: var(--mg-space-56) !important;
}

.gy-60\! {
  row-gap: var(--mg-space-60) !important;
}
