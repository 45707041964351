.social-link {
  position: relative;
  display: block;
  padding-left: 24px;
  text-decoration: none;
  color: var(--mg-gray-7);

  &:hover {
    color: var(--mg-blue-10);
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 20px;
    content: '';
    transform: translateY(-50%);
  }

  &.social-tg {
    padding-left: 21px;

    &:before {
      background: url('/assets/images/icons/soc-tg-ic.svg') center no-repeat;
      background-size: 15px 15px;
    }
  }

  &.social-mail:before {
    width: 17px;
    background: url('/assets/images/icons/soc-mail-ic.svg') center no-repeat;
    background-size: 17px 14px;
  }

  &.social-tg-color:before, &.social-tg:hover:before {
    background-image: url('/assets/images/icons/soc-tg-hvr-ic.svg');
  }

  &.social-mail-color:before, &.social-mail:hover:before {
    background-image: url('/assets/images/icons/soc-mail-hvr-ic.svg');
  }

  &.social-tel {
    display: flex;
    align-items: center;
    padding-left: 0;
    column-gap: var(--mg-space-8);

    &::before {
      content: none;
    }

    i {
      font-size: 16px;
    }
  }
}

.social-button {
  position: relative;
  display: block;
  text-decoration: none;

  &.social-inst {
    width: 28px;
    height: 28px;
    background: url('../../../assets/images/icons/soc-inst-ic.svg') center no-repeat;

    &:hover {
      background: url('../../../assets/images/icons/soc-inst-hvr-ic.svg') center no-repeat;
    }
  }

  &.social-tg {
    width: 27px;
    height: 27px;
    background: url('../../../assets/images/icons/soc-tg-ic.svg') center no-repeat;

    &:hover {
      background: url('../../../assets/images/icons/soc-tg-hvr-ic.svg') center no-repeat;
    }
  }

  &.social-yt {
    width: 40px;
    height: 25px;
    background: url('../../../assets/images/icons/soc-yt-ic.svg') center no-repeat;

    &:hover {
      background: url('../../../assets/images/icons/soc-yt-hvr-ic.svg') center no-repeat;
    }
  }
}
