.font-xxxs {
  font: var(--mg-font-xxxs);
}

.font-xxs {
  font: var(--mg-font-xxs);
}

.font-xs {
  font: var(--mg-font-xs);
}

.font-s {
  font: var(--mg-font-s);
}

.font-m {
  font: var(--mg-font-m);
}

.font-l {
  font: var(--mg-font-l);
}

.font-xl {
  font: var(--mg-font-xl);
}

.font-medium-xxxs {
  font: var(--mg-font-medium-xxxs);
}

.font-medium-xxs {
  font: var(--mg-font-medium-xxs);
}

.font-medium-xs {
  font: var(--mg-font-medium-xs);
}

.font-medium-s {
  font: var(--mg-font-medium-s);
}

.font-medium-m {
  font: var(--mg-font-medium-m);
}

.font-medium-l {
  font: var(--mg-font-medium-l);
}

.font-medium-xl {
  font: var(--mg-font-medium-xl);
}

.font-semibold-xxxs {
  font: var(--mg-font-semibold-xxxs);
}

.font-semibold-xxs {
  font: var(--mg-font-semibold-xxs);
}

.font-semibold-xs {
  font: var(--mg-font-semibold-xs);
}

.font-semibold-s {
  font: var(--mg-font-semibold-s);
}

.font-semibold-m {
  font: var(--mg-font-semibold-m);
}

.font-semibold-l {
  font: var(--mg-font-semibold-l);
}

.font-semibold-xl {
  font: var(--mg-font-semibold-xl);
}

.font-xxxs\! {
  font: var(--mg-font-xxxs) !important;
}

.font-xxs\! {
  font: var(--mg-font-xxs) !important;
}

.font-xs\! {
  font: var(--mg-font-xs) !important;
}

.font-s\! {
  font: var(--mg-font-s) !important;
}

.font-m\! {
  font: var(--mg-font-m) !important;
}

.font-l\! {
  font: var(--mg-font-l) !important;
}

.font-xl\! {
  font: var(--mg-font-xl) !important;
}

.font-medium-xxxs\! {
  font: var(--mg-font-medium-xxxs) !important;
}

.font-medium-xxs\! {
  font: var(--mg-font-medium-xxs) !important;
}

.font-medium-xs\! {
  font: var(--mg-font-medium-xs) !important;
}

.font-medium-s\! {
  font: var(--mg-font-medium-s) !important;
}

.font-medium-m\! {
  font: var(--mg-font-medium-m) !important;
}

.font-medium-l\! {
  font: var(--mg-font-medium-l) !important;
}

.font-medium-xl\! {
  font: var(--mg-font-medium-xl) !important;
}

.font-semibold-xxxs\! {
  font: var(--mg-font-semibold-xxxs) !important;
}

.font-semibold-xxs\! {
  font: var(--mg-font-semibold-xxs) !important;
}

.font-semibold-xs\! {
  font: var(--mg-font-semibold-xs) !important;
}

.font-semibold-s\! {
  font: var(--mg-font-semibold-s) !important;
}

.font-semibold-m\! {
  font: var(--mg-font-semibold-m) !important;
}

.font-semibold-l\! {
  font: var(--mg-font-semibold-l) !important;
}

.font-semibold-xl\! {
  font: var(--mg-font-semibold-xl) !important;
}

.fs-xxxs {
  font-size: var(--mg-fs-xxxs);
}

.fs-xxs {
  font-size: var(--mg-fs-xxs);
}

.fs-xs {
  font-size: var(--mg-fs-xs);
}

.fs-s {
  font-size: var(--mg-fs-s);
}

.fs-m {
  font-size: var(--mg-fs-m);
}

.fs-l {
  font-size: var(--mg-fs-l);
}

.fs-xl {
  font-size: var(--mg-fs-xl);
}

.fs-xxxs\! {
  font-size: var(--mg-fs-xxxs) !important;
}

.fs-xxs\! {
  font-size: var(--mg-fs-xxs) !important;
}

.fs-xs\! {
  font-size: var(--mg-fs-xs) !important;
}

.fs-s\! {
  font-size: var(--mg-fs-s) !important;
}

.fs-m\! {
  font-size: var(--mg-fs-m) !important;
}

.fs-l\! {
  font-size: var(--mg-fs-l) !important;
}

.fs-xl\! {
  font-size: var(--mg-fs-xl) !important;
}
