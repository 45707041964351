$medium: 500;
$semibold: 600;

$xxxs: 1rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 2rem;
$xl: 2.4rem;

$font-family: Manrope, sans-serif;

$font-xxxs: #{$xxxs}/1.4rem #{$font-family};
$font-xxs: #{$xxs}/1.6rem #{$font-family};
$font-xs: #{$xs}/1.8rem #{$font-family};
$font-s: #{$s}/2rem #{$font-family};
$font-m: #{$m}/2.2rem #{$font-family};
$font-l: #{$l}/2.8rem #{$font-family};
$font-xl: #{$xl}/3.2rem #{$font-family};

:root {
  --mg-fs-xxxs: #{$xxxs};
  --mg-fs-xxs: #{$xxs};
  --mg-fs-xs: #{$xs};
  --mg-fs-s: #{$s};
  --mg-fs-m: #{$m};
  --mg-fs-l: #{$l};
  --mg-fs-xl: #{$xl};

  --mg-font-xxxs: #{$font-xxxs};
  --mg-font-xxs: #{$font-xxs};
  --mg-font-xs: #{$font-xs};
  --mg-font-s: #{$font-s};
  --mg-font-m: #{$font-m};
  --mg-font-l: #{$font-l};
  --mg-font-xl: #{$font-xl};

  --mg-font-medium-xxxs: #{$medium} #{$font-xxxs};
  --mg-font-medium-xxs: #{$medium} #{$font-xxs};
  --mg-font-medium-xs: #{$medium} #{$font-xs};
  --mg-font-medium-s: #{$medium} #{$font-s};
  --mg-font-medium-m: #{$medium} #{$font-m};
  --mg-font-medium-l: #{$medium} #{$font-l};
  --mg-font-medium-xl: #{$medium} #{$font-xl};

  --mg-font-semibold-xxxs: #{$semibold} #{$font-xxxs};
  --mg-font-semibold-xxs: #{$semibold} #{$font-xxs};
  --mg-font-semibold-xs: #{$semibold} #{$font-xs};
  --mg-font-semibold-s: #{$semibold} #{$font-s};
  --mg-font-semibold-m: #{$semibold} #{$font-m};
  --mg-font-semibold-l: #{$semibold} #{$font-l};
  --mg-font-semibold-xl: #{$semibold} #{$font-xl};
}
