.nav {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: calc(100vw - 420px);
  column-gap: var(--mg-space-24);

  &__dropdown {
    display: flex;
    flex-direction: column;
    padding: var(--mg-space-8);
    border-radius: var(--mg-radius-8);
    background-color: var(--mg-color-light);
    row-gap: var(--mg-space-4);

    .nav__item {
      width: 100%;
      padding: var(--mg-space-8);
      border-radius: var(--mg-radius-8);
    }
  }

  &__dropdown_btn {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    display: none;
    align-items: center;
    padding: 0;
    color: var(--mg-color-accent-30);
    border: 0;
    background: none;
    column-gap: var(--mg-space-4);
    appearance: none;

    i {
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &:active {
      color: var(--mg-color-accent-50);
    }

    &.active i {
      transform: rotateX(180deg);
    }
  }

  &__item {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    color: var(--mg-color-primary-30);

    i {
      margin-left: var(--mg-space-4);
      transition: transform 0.3s ease-in-out;
    }

    &:hover {
      color: var(--mg-color-grey-50);
    }

    &.active {
      font-weight: 600;
    }

    &.opened {
      color: var(--mg-color-grey-50);

      i {
        transform: rotateX(180deg);
      }
    }

    &_btn {
      position: relative;

      div.nav__item {
        display: flex;
        align-items: center;
      }
    }

    &_dropdown {
      position: absolute;
      top: 24px;
      left: 0;
      box-shadow: var(--mg-shadow-dropdown);
    }
  }

  @media screen and (min-width: 992px) {
    &__dropdown .nav__item {
      &:hover {
        color: var(--mg-color-primary-50);
        background-color: var(--mg-color-grey-20);
      }
    }
  }

  @media (max-width: 992px) {
    overflow: auto;
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
    padding: var(--mg-space-12) var(--mg-space-20);
    row-gap: var(--mg-space-16);

    &__item {
      font-size: 14px;
      line-height: 20px;
      padding: 0 !important;

      &_dropdown {
        position: initial;
        padding: var(--mg-space-8) 0 0 var(--mg-space-8);
        box-shadow: none;
        row-gap: var(--mg-space-8);

        &_link {
          display: flex;
          align-items: center;
          column-gap: var(--mg-space-8);

          span {
            font-size: 18px;
            color: var(--mg-color-accent-30);
          }
        }
      }
    }
  }

  @media (max-width: 1300px) and (min-width: 992px) {
    // TODO была проблема с ssr при расчете количества элементов меню, попробовать еще раз с переходом на 17
    a.nav__item:not(.child) {
      &:nth-last-of-type(-n + 3) {
        display: none;
      }
    }

    &__dropdown {
      div.nav__item {
        display: none;
      }

      a.nav__item {
        display: none;

        &:nth-last-of-type(-n + 3) {
          display: inline-block;
        }
      }
    }

    &__dropdown_btn {
      display: flex;
    }
  }
}
