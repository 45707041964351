.numbers-list {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  display: flex;
  flex-wrap: wrap;
  color: var(--mg-gray-7);

  &__item {
    padding-right: 2%;
  }

  &__title {
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 6px;
    color: var(--mg-blue-10);

    @media (max-width: 1199.98px) {
      font-size: 34px;
    }

    @media (max-width: 767.98px) {
      font-size: 26px;
      line-height: 1;
      // margin-bottom: 2px;
    }
  }
}
