.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.embed-responsive:before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}
