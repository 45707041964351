.cdk-overlay-container, .cdk-global-overlay-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10015;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-overlay-pane {
  position: absolute;
  z-index: 1000;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
}

.cdk-global-overlay-wrapper {
  position: absolute;
  z-index: auto;
  display: flex;
  overflow-y: scroll;
  pointer-events: auto;
}

.cdk-overlay-backdrop {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  pointer-events: auto;
  opacity: 0;
  background-color: rgba(21, 21, 21, 0.5);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-global-scrollblock {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.cdk-dialog-container {
  position: relative;
  padding: 24px;
  pointer-events: auto;
  border-radius: 4px;
  background: var(--mg-color-light);
}

.mg-modal {
  width: 500px;
  max-height: calc(100% - 45.5px);

  &--s {
    width: 400px;
  }

  &--l {
    width: 800px;
  }

  &--xl {
    width: 1140px;
  }

  &--xxl {
    width: 1280px;
  }

  &--xl .cdk-dialog-container, &--xxl .cdk-dialog-container {
    padding: 32px;
  }

  &__header {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--mg-color-primary-50);
  }

  &__description {
    padding-top: 8px;
  }

  &__body {
    padding-top: 6px;
  }

  &__actions {
    padding-top: 24px;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    height: 14px;
    padding: 0;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    opacity: 0.7;
    border: 0;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.83728 0.948393C10.0542 0.731435 10.0542 0.379676 9.83728 0.162718C9.62032 -0.0542395 9.26857 -0.0542395 9.05161 0.162718L5 4.21433L0.948393 0.162718C0.731435 -0.0542395 0.379677 -0.0542395 0.162719 0.162718C-0.0542386 0.379676 -0.0542386 0.731435 0.162719 0.948393L4.21433 5L0.162718 9.05161C-0.0542395 9.26857 -0.0542395 9.62032 0.162718 9.83728C0.379676 10.0542 0.731435 10.0542 0.948393 9.83728L5 5.78567L9.05161 9.83728C9.26857 10.0542 9.62032 10.0542 9.83728 9.83728C10.0542 9.62032 10.0542 9.26857 9.83728 9.05161L5.78567 5L9.83728 0.948393Z' fill='%23282E3D'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      opacity: 1;
    }
  }
}
