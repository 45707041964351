.video-modal__close {
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  opacity: 0.6;
  background: url('../../../assets/images/icons/close-ic.svg') center center no-repeat;
  background-size: 12px 12px;

  &:hover {
    opacity: 1;
  }
}

.video-modal__list {
  &__item {
    display: flex;
    align-items: center;
    padding: 14px;
    cursor: pointer;

    &:hover,
    &.active {
      background: #eff0f7;
    }

    img {
      width: 90px;
      border-radius: 8px;
    }
  }

  &__title {
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-left: 14px;
    letter-spacing: initial;
    color: var(--mg-blue-10);
  }
}

.mdc-dialog__content {
  padding: 0 !important;
}

.mdc-dialog.cdk-dialog-container {
  padding: 0;
  background-color: transparent;
}

.mg-modal--nopadding .cdk-dialog-container {
  padding: 0;
}

.authorized-button-modal {
  width: 1200px;

  @media (max-width: 1200px) {
    width: calc(100vw - 24px);
  }

  @media (max-width: 768px) {
    .cdk-dialog-container {
      padding: 12px;
    }
  }
}
