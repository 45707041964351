.tools-list {
  &__content {
    font-size: 15px;
    line-height: 1.4;
    align-items: center;
    padding-top: 60px;
    padding-right: 30px;
    padding-bottom: 30px;

    &-info {
      padding-right: 5%;
    }

    &-title {
      font-size: 1.3em;
      font-weight: 600;
      margin-bottom: 13px;

      @media (max-width: 767.98px) {
        font-size: 14px;
      }
    }
    &-desc {
      font-weight: 500;
      margin-bottom: 26px;
      color: var(--mg-gray-7);

      p {
        margin-bottom: 13px;
      }
    }

    &-preview {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    &-video {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      filter: drop-shadow(4px 5px 11px rgba(40, 46, 61, 0.15));
    }
  }

  @media (max-width: 767.98px) {
    &__content {
      margin-right: -16px;
      margin-left: -16px;
      padding: 16px;
      border-top: 1px solid #ededf0;
    }
    &__item {
      margin-right: -16px;
      margin-bottom: 8px;
      margin-left: -16px;
      padding: 0 16px;
      background: #f9fafb;
    }
    &__title {
      font-size: 15px;
      line-height: 1.3;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      padding-left: 24px;

      cursor: pointer;

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        content: '';
        transform: translateY(-50%);
        background: url('../../../assets/images/icons/arr-dn-black-ic.svg') center no-repeat;
        background-size: 10px 5px;
      }

      &.opened {
        font-weight: 600;

        &:before {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      br {
        display: none;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        top: -14px;
        left: 16px;
        padding: 3px 6px;
        color: #fff;
        border-radius: 4px;
        background: var(--mg-blue-10);
      }
    }
  }
}
