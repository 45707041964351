@use 'fonts' as *;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font: var(--mg-font-xs);
  color: var(--mg-color-primary-50);
  background: var(--mg-color-grey-10);
}

a {
  text-decoration: none;
}

button {
  color: inherit;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  font-family: inherit;

  &:has(i) {
    display: flex;
  }
}

input,
textarea {
  font-family: inherit;
}
