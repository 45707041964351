:root {
  @each $colorPack, $valuePack in $colors {
    @each $color, $value in $valuePack {
      --#{$prefix}#{$color}: #{$value};
    }
  }

  --#{$prefix}box-shadow-sm: #{$box-shadow-sm};
  --#{$prefix}box-shadow-md: #{$box-shadow-md};
  --#{$prefix}box-shadow-lg: #{$box-shadow-lg};

  @each $space, $value in $spacers {
    --#{$prefix}space-#{$space}: #{$value};
  }

  @each $radius, $value in $border-radius {
    --#{$prefix}radius-#{$radius}: #{$value};
  }
}

html {
  @each $color, $value in $light-colors {
    --#{$prefix}color-#{$color}: #{$value};
  }

  --#{$prefix}shadow-dropdown: 0 0 16px 0 rgba(148, 148, 176, 0.24);
  --#{$prefix}shadow-small-element: 0 0 2px 0 rgba(105, 105, 122, 0.24);
}

html[data-theme="Dark"] {
  @each $color, $value in $dark-colors {
    --#{$prefix}color-#{$color}: #{$value};
  }

  --#{$prefix}shadow-dropdown: 0 0 16px 0 rgba(23, 23, 26, 0.48);
  --#{$prefix}shadow-small-element: 0 0 2px 0 rgba(23, 23, 26, 0.24);
}
