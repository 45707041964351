.main-screen {
  display: flex;
  align-items: center;

  &__info {
    font-size: 16px;
    line-height: 1.4;
    flex: 0 0 auto;
    width: 50%;
    padding: 0 20px;

    @media (max-width: 1199.98px) {
      font-size: 13px;
      padding-right: 4%;
    }

    @media (max-width: 767.98px) {
      width: 100%;
      padding: 0;
    }

    &-title {
      font-size: 2.5em;
      font-weight: 800;
      line-height: 1.2em;
      margin-bottom: 26px;

      @media (max-width: 767.98px) {
        font-size: 1.5em;
        margin-bottom: 12px;
        padding-right: 0;
      }
    }
    &-desc {
      margin-bottom: 20px;
      color: var(--mg-gray-7);
    }
  }
  &__video {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    filter: drop-shadow(4px 5px 11px rgba(40, 46, 61, 0.15));

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
