@use '@angular/material' as mat;
@include mat.core();

@import '../node_modules/@marketguru/ui-kit-components/assets/styles/scss/main';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

html {
  height: 100%;
}

body {
  height: 100%;
  background: var(--mg-color-light) !important;
}

a:hover {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 172rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0 var(--mg-space-60);

  @media (max-width: 1280px) {
    padding: 0 var(--mg-space-52);
  }

  @media (max-width: 992px) {
    padding: 0 var(--mg-space-20);
  }

  @media (max-width: 767px) {
    padding: 0 var(--mg-space-16);
  }

  &--wide {
    max-width: 100%;
    padding: 0 var(--mg-space-20);
  }
}

.section {
  margin-bottom: 12rem;

  @media (max-width: 1199.98px) {
    margin-bottom: 10rem;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 5rem;
  }
}
.bg-section {
  padding: 4rem;
  border-radius: var(--mg-radius-4);
  background: #f9fafb;

  @media (max-width: 1199.98px) {
    padding: 26px;
  }

  @media (max-width: 767.98px) {
    margin-right: -16px;
    margin-left: -16px;
    padding: 13px;
  }
}

.content-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;

  b {
    font-weight: 800;
  }

  @media (max-width: 1199.98px) {
    font-size: 24px;

    br {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

.landing-title {
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.4;
  text-align: center;
  color: var(--mg-color-primary-50);

  @media (max-width: 767.98px) {
    font-size: var(--mg-fs-xl);
  }
}

@import './modules/buttons';
@import './modules/mat-tab';
@import './modules/main-screen';
@import './modules/tools';
@import './modules/article';
@import './modules/numbers';
@import './modules/gallery';
@import './modules/social';
@import './modules/video-modal';
@import './modules/nav';
@import './modules/forms';
@import './modules/toastr';
@import './modules/banner-fonts';
@import './pages/advantages-section';

// TODO переделать на китовый
.fs-xs {
  font-size: 13px;
}
.positive {
  color: var(--mg-green-6);
}
.negative {
  color: var(--mg-orange-6);
}

.label-new {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 2px 6px;
  color: #fff;
  border-radius: 4px;
  background: var(--mg-color-primary-50);
}

.article-block ul,
.tools-list ul {
  margin: 0;
  padding-left: var(--mg-space-20);

  li {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: var(--mg-space-8);
    color: var(--mg-color-grey-50);

    &::marker {
      color: var(--mg-color-accent-30);
    }
  }
}

.tariff-select {
  ul {
    padding-left: 16px;

    li {
      padding: 0;
      list-style: initial;

      &:before {
        content: none;
      }
    }
  }
}

.tariff-list__features {
  &-title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
      flex: 1 1 50%;
      width: 50%;
      margin-top: 5px;
      padding-left: 20px;
      list-style: none;
      background: url('../../assets/images/icons/list-style-pink-ic.svg') 2px 6px no-repeat;
      background-size: 12px 8px;

      @media (max-width: 991.98px) {
        flex: 1 1 100%;
        width: 100%;
      }

      &:before {
        display: none;
      }
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-none {
  display: none !important;
}

.d-flex-grow-1 {
  flex-grow: 1;
}

input {
  font-family: inherit;
}

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .col-md-6 {
    width: 50%;
  }
}

@media (min-width: 575px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-flex {
    display: flex !important;
  }
}

.new-year {
  position: relative;

  .snowflake {
    position: absolute;
  }
}
