.align-items-stretch {
  align-items: stretch;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch\! {
  align-items: stretch !important;
}

.align-items-start\! {
  align-items: flex-start !important;
}

.align-items-end\! {
  align-items: flex-end !important;
}

.align-items-center\! {
  align-items: center !important;
}

.align-items-baseline\! {
  align-items: baseline !important;
}
