.mdc-tab-indicator {
  display: none !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
  height: auto;
}

.mat-mdc-tab-label-container {
  border: none !important;
}

.tools-list {
  .mdc-tab {
    font-size: 14px;
    margin-right: 16px;
    padding: var(--mg-space-20) 0;
    text-align: left;
    opacity: 1;

    &__content {
      min-width: 114px;
    }

    &__text-label {
      position: relative;
      justify-content: flex-start;
      width: 100%;
      padding: 0;
      color: var(--mg-color-grey-50) !important;
      border: 1px solid var(--mg-color-grey-50);
      border-radius: var(--mg-radius-4);
      background: transparent;

      .tools-list__btn {
        font-size: inherit;
        font-weight: 400;
        width: 100%;
        padding: var(--mg-space-8) var(--mg-space-12);
        transition: none;
        text-align: left;
        color: inherit;
        border: 0;
        background: transparent;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        top: -14px;
        left: 16px;
        padding: var(--mg-space-4) var(--mg-space-6);
        color: var(--mg-color-light);
        border-radius: var(--mg-radius-4);
        background: var(--mg-color-primary-50);
      }
    }

    &--active .mdc-tab__text-label {
      color: var(--mg-color-light) !important;
      background: var(--mg-color-primary-50) !important;

      span {
        color: var(--mg-color-grey-50);
        background: var(--mg-color-light);
        box-shadow: 2px 0 13px rgba(40, 46, 61, 0.12);
      }
    }
  }
}
