.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0\! {
  flex-grow: 0 !important;
}

.flex-grow-1\! {
  flex-grow: 1 !important;
}
