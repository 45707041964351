@mixin light-theme {
  --mg-color-accent-10: #fcedff;
  --mg-color-accent-20: #f7cefe;
  --mg-color-accent-25: #eda9f9;
  --mg-color-accent-28: #d64feb;
  --mg-color-accent-30: #ad46bd;
  --mg-color-accent-40: #933fa1;
  --mg-color-accent-50: #832393;

  --mg-color-primary-30: #3b4a71;
  --mg-color-primary-40: #343c52;
  --mg-color-primary-50: #282e3d;

  --mg-color-light: #ffffff;

  --mg-color-grey-10: #f4f7f9;
  --mg-color-grey-20: #eff0f7;
  --mg-color-grey-30: #e1e2eb;
  --mg-color-grey-40: #a2a2ba;
  --mg-color-grey-50: #7a7a94;

  --mg-color-green-10: #e3f9e5;
  --mg-color-green-20: #c1f2c7;
  --mg-color-green-30: #72dd61;
  --mg-color-green-40: #34b41f;
  --mg-color-green-45: #18981d;
  --mg-color-green-50: #0e7817;
  --mg-color-green-60: #07600e;

  --mg-color-red-10: #ffe3e3;
  --mg-color-red-20: #ffbdbd;
  --mg-color-red-30: #fb5f6a;
  --mg-color-red-40: #f43845;
  --mg-color-red-50: #ab091e;
  --mg-color-red-60: #8a041a;

  --mg-color-yellow-10: #fff7e0;
  --mg-color-yellow-20: #fcefc7;
  --mg-color-yellow-30: #f8cd61;
  --mg-color-yellow-40: #fcbb14;
  --mg-color-yellow-50: #ab7f0d;
  --mg-color-yellow-60: #7c5e10;

  --mg-color-orange-10: #ffe8d9;
  --mg-color-orange-20: #ffd0b5;
  --mg-color-orange-30: #ffb088;
  --mg-color-orange-40: #f37027;
  --mg-color-orange-50: #bc5820;
  --mg-color-orange-60: #a04822;

  --mg-color-blue-10: #e2ebff;
  --mg-color-blue-20: #cbdaff;
  --mg-color-blue-30: #97b5ff;
  --mg-color-blue-40: #4479ff;
  --mg-color-blue-50: #1d3dbf;
  --mg-color-blue-60: #132dad;

  --mg-color-cyan-10: #e1fcf8;
  --mg-color-cyan-20: #c1fef6;
  --mg-color-cyan-30: #2fad96;
  --mg-color-cyan-40: #07818f;
  --mg-color-cyan-50: #05606e;

  --mg-color-salad-30: #aadc42;
  --mg-color-salad-40: #89bc20;

  --mg-color-pink-30: #ff6bd6;
  --mg-color-pink-40: #e43fb6;

  --mg-color-eggplant-30: #8637c5;
  --mg-color-eggplant-40: #722aab;

  --mg-color-accent-10-rgb: 252, 237, 255;
  --mg-color-accent-20-rgb: 247, 206, 254;
  --mg-color-accent-25-rgb: 237, 169, 249;
  --mg-color-accent-28-rgb: 214, 79, 235;
  --mg-color-accent-30-rgb: 173, 70, 189;
  --mg-color-accent-40-rgb: 147, 63, 161;
  --mg-color-accent-50-rgb: 131, 35, 147;

  --mg-color-primary-30-rgb: 59, 74, 113;
  --mg-color-primary-40-rgb: 52, 60, 82;
  --mg-color-primary-50-rgb: 40, 46, 61;

  --mg-color-light-rgb: 255, 255, 255;

  --mg-color-grey-10-rgb: 244, 247, 249;
  --mg-color-grey-20-rgb: 239, 240, 247;
  --mg-color-grey-30-rgb: 225, 226, 235;
  --mg-color-grey-40-rgb: 162, 162, 186;
  --mg-color-grey-50-rgb: 122, 122, 148;

  --mg-color-green-10-rgb: 227, 249, 229;
  --mg-color-green-20-rgb: 193, 242, 199;
  --mg-color-green-30-rgb: 114, 221, 97;
  --mg-color-green-40-rgb: 52, 180, 31;
  --mg-color-green-45-rgb: 24, 152, 29;
  --mg-color-green-50-rgb: 14, 120, 23;
  --mg-color-green-60-rgb: 7, 96, 14;

  --mg-color-red-10-rgb: 255, 227, 227;
  --mg-color-red-20-rgb: 255, 189, 189;
  --mg-color-red-30-rgb: 251, 95, 106;
  --mg-color-red-40-rgb: 244, 56, 69;
  --mg-color-red-50-rgb: 171, 9, 30;
  --mg-color-red-60-rgb: 138, 4, 26;

  --mg-color-yellow-10-rgb: 255, 247, 224;
  --mg-color-yellow-20-rgb: 252, 239, 199;
  --mg-color-yellow-30-rgb: 248, 205, 97;
  --mg-color-yellow-40-rgb: 252, 187, 20;
  --mg-color-yellow-50-rgb: 171, 127, 13;
  --mg-color-yellow-60-rgb: 124, 94, 16;

  --mg-color-orange-10-rgb: 255, 232, 217;
  --mg-color-orange-20-rgb: 255, 208, 181;
  --mg-color-orange-30-rgb: 255, 176, 136;
  --mg-color-orange-40-rgb: 243, 112, 39;
  --mg-color-orange-50-rgb: 188, 88, 32;
  --mg-color-orange-60-rgb: 160, 72, 34;

  --mg-color-blue-10-rgb: 226, 235, 255;
  --mg-color-blue-20-rgb: 203, 218, 255;
  --mg-color-blue-30-rgb: 151, 181, 255;
  --mg-color-blue-40-rgb: 68, 121, 255;
  --mg-color-blue-50-rgb: 29, 61, 191;
  --mg-color-blue-60-rgb: 19, 45, 173;

  --mg-color-cyan-10-rgb: 225, 252, 248;
  --mg-color-cyan-20-rgb: 193, 254, 246;
  --mg-color-cyan-30-rgb: 47, 173, 150;
  --mg-color-cyan-40-rgb: 7, 129, 143;
  --mg-color-cyan-50-rgb: 5, 96, 110;

  --mg-color-salad-30-rgb: 170, 220, 66;
  --mg-color-salad-40-rgb: 137, 188, 32;

  --mg-color-pink-30-rgb: 255, 107, 214;
  --mg-color-pink-40-rgb: 228, 63, 182;

  --mg-color-eggplant-30-rgb: 134, 55, 197;
  --mg-color-eggplant-40-rgb: 114, 42, 171;

  --mg-shadow-dropdown: 0 0 1.6rem 0 rgba(148, 148, 176, 0.24);
  --mg-shadow-small-element: 0 0 0.2rem 0 rgba(105, 105, 122, 0.24);

  --overlay-1: rgba(239, 240, 247, 0.8);
  --overlay-2: rgba(247, 206, 254, 0.8);
  --overlay-3: rgba(38, 38, 40, 0.52);

  color-scheme: ligth;
}

@mixin dark-theme {
  --mg-color-accent-10: #512759;
  --mg-color-accent-20: #733a7e;
  --mg-color-accent-25: #964ca4;
  --mg-color-accent-28: #ca65dd;
  --mg-color-accent-30: #dc6ded;
  --mg-color-accent-40: #f0a3fc;
  --mg-color-accent-50: #f7c1ff;

  --mg-color-primary-30: #87a1e0;
  --mg-color-primary-40: #afc5ff;
  --mg-color-primary-50: #ffffff;

  --mg-color-light: #2c2c31;

  --mg-color-grey-10: #262628;
  --mg-color-grey-20: #37373f;
  --mg-color-grey-30: #3e3e48;
  --mg-color-grey-40: #87879c;
  --mg-color-grey-50: #b6b6cc;

  --mg-color-green-10: #205025;
  --mg-color-green-20: #2d6633;
  --mg-color-green-30: #33873c;
  --mg-color-green-40: #34b41f;
  --mg-color-green-45: #40bf2b;
  --mg-color-green-50: #5cdc47;
  --mg-color-green-60: #80f56c;

  --mg-color-red-10: #612f2f;
  --mg-color-red-20: #893131;
  --mg-color-red-30: #b33636;
  --mg-color-red-40: #f43845;
  --mg-color-red-50: #ff606a;
  --mg-color-red-60: #ff9ca3;

  --mg-color-yellow-10: #635220;
  --mg-color-yellow-20: #846920;
  --mg-color-yellow-30: #ab8a2f;
  --mg-color-yellow-40: #fcbb14;
  --mg-color-yellow-50: #ffd568;
  --mg-color-yellow-60: #ffe49d;

  --mg-color-orange-10: #5f3e28;
  --mg-color-orange-20: #794422;
  --mg-color-orange-30: #a74f16;
  --mg-color-orange-40: #f37027;
  --mg-color-orange-50: #ff9356;
  --mg-color-orange-60: #ffbc97;

  --mg-color-blue-10: #273553;
  --mg-color-blue-20: #304575;
  --mg-color-blue-30: #3a59a0;
  --mg-color-blue-40: #4479ff;
  --mg-color-blue-50: #7aa0ff;
  --mg-color-blue-60: #a5beff;

  --mg-color-cyan-10: #33534e;
  --mg-color-cyan-20: #2e6a61;
  --mg-color-cyan-30: #2fad96;
  --mg-color-cyan-40: #2cdaba;
  --mg-color-cyan-50: #5effe2;

  --mg-color-salad-30: #87b821;
  --mg-color-salad-40: #aadc42;

  --mg-color-pink-30: #db3f9c;
  --mg-color-pink-40: #ff6bd6;

  --mg-color-eggplant-30: #6a289e;
  --mg-color-eggplant-40: #8637c5;

  --mg-color-accent-10-rgb: 81, 39, 89;
  --mg-color-accent-20-rgb: 115, 58, 126;
  --mg-color-accent-25-rgb: 150, 76, 164;
  --mg-color-accent-28-rgb: 202, 101, 221;
  --mg-color-accent-30-rgb: 220, 109, 237;
  --mg-color-accent-40-rgb: 240, 163, 252;
  --mg-color-accent-50-rgb: 247, 193, 255;

  --mg-color-primary-30-rgb: 135, 161, 224;
  --mg-color-primary-40-rgb: 175, 197, 255;
  --mg-color-primary-50-rgb: 255, 255, 255;

  --mg-color-light-rgb: 44, 44, 49;

  --mg-color-grey-10-rgb: 38, 38, 40;
  --mg-color-grey-20-rgb: 55, 55, 63;
  --mg-color-grey-30-rgb: 62, 62, 72;
  --mg-color-grey-40-rgb: 135, 135, 156;
  --mg-color-grey-50-rgb: 182, 182, 204;

  --mg-color-green-10-rgb: 32, 80, 37;
  --mg-color-green-20-rgb: 45, 102, 51;
  --mg-color-green-30-rgb: 51, 135, 60;
  --mg-color-green-40-rgb: 52, 180, 31;
  --mg-color-green-45-rgb: 64, 191, 43;
  --mg-color-green-50-rgb: 92, 220, 71;
  --mg-color-green-60-rgb: 128, 245, 108;

  --mg-color-red-10-rgb: 97, 47, 47;
  --mg-color-red-20-rgb: 137, 49, 49;
  --mg-color-red-30-rgb: 179, 54, 54;
  --mg-color-red-40-rgb: 244, 56, 69;
  --mg-color-red-50-rgb: 255, 96, 106;
  --mg-color-red-60-rgb: 255, 156, 163;

  --mg-color-yellow-10-rgb: 99, 82, 32;
  --mg-color-yellow-20-rgb: 132, 105, 32;
  --mg-color-yellow-30-rgb: 171, 138, 47;
  --mg-color-yellow-40-rgb: 252, 187, 20;
  --mg-color-yellow-50-rgb: 255, 213, 104;
  --mg-color-yellow-60-rgb: 255, 228, 157;

  --mg-color-orange-10-rgb: 95, 62, 40;
  --mg-color-orange-20-rgb: 121, 68, 34;
  --mg-color-orange-30-rgb: 167, 79, 22;
  --mg-color-orange-40-rgb: 243, 112, 39;
  --mg-color-orange-50-rgb: 255, 147, 86;
  --mg-color-orange-60-rgb: 255, 188, 151;

  --mg-color-blue-10-rgb: 39, 53, 83;
  --mg-color-blue-20-rgb: 48, 69, 117;
  --mg-color-blue-30-rgb: 58, 89, 160;
  --mg-color-blue-40-rgb: 68, 121, 255;
  --mg-color-blue-50-rgb: 122, 160, 255;
  --mg-color-blue-60-rgb: 165, 190, 255;

  --mg-color-cyan-10-rgb: 51, 83, 78;
  --mg-color-cyan-20-rgb: 46, 106, 97;
  --mg-color-cyan-30-rgb: 47, 173, 150;
  --mg-color-cyan-40-rgb: 44, 218, 186;
  --mg-color-cyan-50-rgb: 94, 255, 226;

  --mg-color-salad-30-rgb: 135, 184, 33;
  --mg-color-salad-40-rgb: 170, 220, 66;

  --mg-color-pink-30-rgb: 219, 63, 156;
  --mg-color-pink-40-rgb: 255, 107, 214;

  --mg-color-eggplant-30-rgb: 106, 40, 158;
  --mg-color-eggplant-40-rgb: 134, 55, 197;

  --mg-shadow-dropdown: 0 0 1.6rem 0 rgba(23, 23, 26, 0.48);
  --mg-shadow-small-element: 0 0 0.2rem 0 rgba(23, 23, 26, 0.24);

  --overlay-1: rgba(55, 55, 63, 0.8);
  --overlay-2: rgba(115, 58, 126, 0.8);
  --overlay-3: rgba(56, 56, 64, 0.72);

  color-scheme: dark;
}

.light-theme {
  @include light-theme;
}

.dark-theme {
  @include dark-theme;
}

// OS Theme.
:root:not(.light-theme):not(.dark-theme) {
  @media (prefers-color-scheme: light) {
    @include light-theme;
  }

  @media (prefers-color-scheme: dark) {
    @include dark-theme;
  }
}
