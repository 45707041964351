$prefix: mg-;

$font-family: "Manrope", sans-serif;

/**
  Описание отступов
  https://www.figma.com/file/d1B3w8tmDqKVWF7UfJEzWs/DS-MG-2.0?type=design&node-id=607%3A9622&mode=dev
 */
$spacers: (
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  28: 28px,
  32: 32px,
  36: 36px,
  40: 40px,
  44: 44px,
  48: 48px,
  52: 52px,
  56: 56px,
  60: 60px,
);

$box-shadow-sm: 1px 0px 6px rgba(40, 46, 61, 0.12);
$box-shadow-md: 2px 0px 13px rgba(40, 46, 61, 0.12);
$box-shadow-lg: 4px 5px 11px rgba(40, 46, 61, 0.15);

$gutter-x: 12px;

$grid-columns: 12;

/**
  Описание скруглений
  https://www.figma.com/file/d1B3w8tmDqKVWF7UfJEzWs/DS-MG-2.0?type=design&node-id=607-9540&mode=dev
 */
$border-radius: (
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
);
