.advantages-list {
  ul {
    li {
      margin-bottom: var(--mg-space-12);

      &::marker {
        color: var(--mg-color-accent-30);
      }
    }
  }
}
